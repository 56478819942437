import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layout/AdminLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
          title: 'Home',
        },
      },
      {
        path: '/orders',
        name: 'orders',
        component: () => import('@/views/orders/OrderListView.vue'),
        meta: {
          title: 'Orders',
        },
      },
      {
        path: '/orders/:id',
        name: 'orderDetails',
        component: () => import('@/views/orders/OrderDetailsView.vue'),
        meta: {
          title: 'Orders Details',
        },
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/layout/AuthLayout.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/views/auth/LoginView.vue'),
        meta: {
          title: 'Login',
        },
      },
    ],
  },
  {
    path: '/register',
    component: () => import('@/layout/AuthLayout.vue'),
    children: [
      {
        path: '',
        name: 'register',
        component: () => import('@/views/auth/RegisterView.vue'),
        meta: {
          title: 'Register',
        },
      },
    ],
  },
  {
    path: '/verify',
    component: () => import('@/layout/AuthLayout.vue'),
    children: [
      {
        path: '',
        name: 'verify',
        component: () => import('@/views/auth/VerifyView.vue'),
        meta: {
          title: 'Verify',
        },
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];
