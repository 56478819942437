/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailScheduleDto
 */
export interface EmailScheduleDto {
    /**
     * Recipient email
     * @type {string}
     * @memberof EmailScheduleDto
     */
    recipient: string;
    /**
     * Email subject
     * @type {string}
     * @memberof EmailScheduleDto
     */
    subject: string;
    /**
     * Email body content
     * @type {string}
     * @memberof EmailScheduleDto
     */
    content: string;
    /**
     * Date at which the email should be sent (in ISO format)
     * @type {string}
     * @memberof EmailScheduleDto
     */
    date: string;
}

/**
 * Check if a given object implements the EmailScheduleDto interface.
 */
export function instanceOfEmailScheduleDto(value: object): value is EmailScheduleDto {
    if (!('recipient' in value) || value['recipient'] === undefined) return false;
    if (!('subject' in value) || value['subject'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function EmailScheduleDtoFromJSON(json: any): EmailScheduleDto {
    return EmailScheduleDtoFromJSONTyped(json, false);
}

export function EmailScheduleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailScheduleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'recipient': json['recipient'],
        'subject': json['subject'],
        'content': json['content'],
        'date': json['date'],
    };
}

export function EmailScheduleDtoToJSON(value?: EmailScheduleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'recipient': value['recipient'],
        'subject': value['subject'],
        'content': value['content'],
        'date': value['date'],
    };
}

