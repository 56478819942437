/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateOrUpdateDeliveryFeeDto } from './CreateOrUpdateDeliveryFeeDto';
import {
    CreateOrUpdateDeliveryFeeDtoFromJSON,
    CreateOrUpdateDeliveryFeeDtoFromJSONTyped,
    CreateOrUpdateDeliveryFeeDtoToJSON,
} from './CreateOrUpdateDeliveryFeeDto';

/**
 * 
 * @export
 * @interface CreateOrUpdateDeliveryDto
 */
export interface CreateOrUpdateDeliveryDto {
    /**
     * Delivery type
     * @type {string}
     * @memberof CreateOrUpdateDeliveryDto
     */
    type: CreateOrUpdateDeliveryDtoTypeEnum;
    /**
     * ID of the associated delivery fee
     * @type {Array<CreateOrUpdateDeliveryFeeDto>}
     * @memberof CreateOrUpdateDeliveryDto
     */
    deliveryFee?: Array<CreateOrUpdateDeliveryFeeDto>;
    /**
     * Delivery description in English
     * @type {number}
     * @memberof CreateOrUpdateDeliveryDto
     */
    descriptionEn?: number;
    /**
     * Delivery description in Arabic
     * @type {number}
     * @memberof CreateOrUpdateDeliveryDto
     */
    descriptionAr?: number;
}


/**
 * @export
 */
export const CreateOrUpdateDeliveryDtoTypeEnum = {
    Asap: 'ASAP',
    SameDay: 'SAME_DAY',
    NextDay: 'NEXT_DAY',
    InTwoDays: 'IN_TWO_DAYS'
} as const;
export type CreateOrUpdateDeliveryDtoTypeEnum = typeof CreateOrUpdateDeliveryDtoTypeEnum[keyof typeof CreateOrUpdateDeliveryDtoTypeEnum];


/**
 * Check if a given object implements the CreateOrUpdateDeliveryDto interface.
 */
export function instanceOfCreateOrUpdateDeliveryDto(value: object): value is CreateOrUpdateDeliveryDto {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function CreateOrUpdateDeliveryDtoFromJSON(json: any): CreateOrUpdateDeliveryDto {
    return CreateOrUpdateDeliveryDtoFromJSONTyped(json, false);
}

export function CreateOrUpdateDeliveryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrUpdateDeliveryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'deliveryFee': json['deliveryFee'] == null ? undefined : ((json['deliveryFee'] as Array<any>).map(CreateOrUpdateDeliveryFeeDtoFromJSON)),
        'descriptionEn': json['descriptionEn'] == null ? undefined : json['descriptionEn'],
        'descriptionAr': json['descriptionAr'] == null ? undefined : json['descriptionAr'],
    };
}

export function CreateOrUpdateDeliveryDtoToJSON(value?: CreateOrUpdateDeliveryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'deliveryFee': value['deliveryFee'] == null ? undefined : ((value['deliveryFee'] as Array<any>).map(CreateOrUpdateDeliveryFeeDtoToJSON)),
        'descriptionEn': value['descriptionEn'],
        'descriptionAr': value['descriptionAr'],
    };
}

