/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminUserDto
 */
export interface AdminUserDto {
    /**
     * Admin user UUID
     * @type {string}
     * @memberof AdminUserDto
     */
    id: string;
    /**
     * Admin user firstName
     * @type {string}
     * @memberof AdminUserDto
     */
    firstName: string;
    /**
     * Admin user last name
     * @type {string}
     * @memberof AdminUserDto
     */
    lastName: string;
    /**
     * Admin user email
     * @type {string}
     * @memberof AdminUserDto
     */
    email: string;
    /**
     * Admin user phone number
     * @type {string}
     * @memberof AdminUserDto
     */
    phoneNumber: string;
    /**
     * Admin user password hash
     * @type {string}
     * @memberof AdminUserDto
     */
    password: string;
    /**
     * Admin user roles
     * @type {Array<number>}
     * @memberof AdminUserDto
     */
    roles: Array<number>;
    /**
     * Flag to check if user is confirmed
     * @type {boolean}
     * @memberof AdminUserDto
     */
    isConfirmed: boolean;
    /**
     * Timestamp user was created at
     * @type {Date}
     * @memberof AdminUserDto
     */
    createdAt: Date;
    /**
     * Timestamp user was updated at
     * @type {Date}
     * @memberof AdminUserDto
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the AdminUserDto interface.
 */
export function instanceOfAdminUserDto(value: object): value is AdminUserDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('roles' in value) || value['roles'] === undefined) return false;
    if (!('isConfirmed' in value) || value['isConfirmed'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function AdminUserDtoFromJSON(json: any): AdminUserDto {
    return AdminUserDtoFromJSONTyped(json, false);
}

export function AdminUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'password': json['password'],
        'roles': json['roles'],
        'isConfirmed': json['isConfirmed'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function AdminUserDtoToJSON(value?: AdminUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'password': value['password'],
        'roles': value['roles'],
        'isConfirmed': value['isConfirmed'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

