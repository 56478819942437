/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScrapBatteryPriceDto
 */
export interface ScrapBatteryPriceDto {
    /**
     * Scrap battery price ID
     * @type {number}
     * @memberof ScrapBatteryPriceDto
     */
    id: number;
    /**
     * Scrap battery price value
     * @type {number}
     * @memberof ScrapBatteryPriceDto
     */
    price: number;
    /**
     * Scrap battery price currency
     * @type {string}
     * @memberof ScrapBatteryPriceDto
     */
    currency: ScrapBatteryPriceDtoCurrencyEnum;
    /**
     * Timestamp Scrap battery price was created at
     * @type {Date}
     * @memberof ScrapBatteryPriceDto
     */
    createdAt?: Date;
    /**
     * Timestamp Scrap battery price was updated at
     * @type {Date}
     * @memberof ScrapBatteryPriceDto
     */
    updatedAt?: Date;
}


/**
 * @export
 */
export const ScrapBatteryPriceDtoCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type ScrapBatteryPriceDtoCurrencyEnum = typeof ScrapBatteryPriceDtoCurrencyEnum[keyof typeof ScrapBatteryPriceDtoCurrencyEnum];


/**
 * Check if a given object implements the ScrapBatteryPriceDto interface.
 */
export function instanceOfScrapBatteryPriceDto(value: object): value is ScrapBatteryPriceDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function ScrapBatteryPriceDtoFromJSON(json: any): ScrapBatteryPriceDto {
    return ScrapBatteryPriceDtoFromJSONTyped(json, false);
}

export function ScrapBatteryPriceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScrapBatteryPriceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'price': json['price'],
        'currency': json['currency'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
    };
}

export function ScrapBatteryPriceDtoToJSON(value?: ScrapBatteryPriceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'price': value['price'],
        'currency': value['currency'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

