/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Ping200Response,
  Ping503Response,
} from '../models/index';
import {
    Ping200ResponseFromJSON,
    Ping200ResponseToJSON,
    Ping503ResponseFromJSON,
    Ping503ResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class AppServiceApi extends runtime.BaseAPI {

    /**
     * Test if the application is working properly
     */
    async pingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Ping200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/app/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Ping200ResponseFromJSON(jsonValue));
    }

    /**
     * Test if the application is working properly
     */
    async ping(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Ping200Response> {
        const response = await this.pingRaw(initOverrides);
        return await response.value();
    }

}
