/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOrUpdateDeliveryDto,
  DeliveryDto,
  DeliveryFeeDto,
} from '../models/index';
import {
    CreateOrUpdateDeliveryDtoFromJSON,
    CreateOrUpdateDeliveryDtoToJSON,
    DeliveryDtoFromJSON,
    DeliveryDtoToJSON,
    DeliveryFeeDtoFromJSON,
    DeliveryFeeDtoToJSON,
} from '../models/index';

export interface CreateOrUpdateDeliveryRequest {
    createOrUpdateDeliveryDto: CreateOrUpdateDeliveryDto;
}

export interface DeleteDeliveryRequest {
    id: number;
}

export interface DeleteDeliveryFeeRequest {
    id: number;
    currency: DeleteDeliveryFeeCurrencyEnum;
}

export interface GetDeliveryByIdRequest {
    id: number;
}

export interface GetDeliveryByTypeRequest {
    type: GetDeliveryByTypeTypeEnum;
}

/**
 * 
 */
export class DeliveryServiceApi extends runtime.BaseAPI {

    /**
     * Creates delivery in database
     */
    async createOrUpdateDeliveryRaw(requestParameters: CreateOrUpdateDeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryDto>> {
        if (requestParameters['createOrUpdateDeliveryDto'] == null) {
            throw new runtime.RequiredError(
                'createOrUpdateDeliveryDto',
                'Required parameter "createOrUpdateDeliveryDto" was null or undefined when calling createOrUpdateDelivery().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/delivery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrUpdateDeliveryDtoToJSON(requestParameters['createOrUpdateDeliveryDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryDtoFromJSON(jsonValue));
    }

    /**
     * Creates delivery in database
     */
    async createOrUpdateDelivery(requestParameters: CreateOrUpdateDeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryDto> {
        const response = await this.createOrUpdateDeliveryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes delivery by ID
     */
    async deleteDeliveryRaw(requestParameters: DeleteDeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDelivery().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/delivery/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryDtoFromJSON(jsonValue));
    }

    /**
     * Deletes delivery by ID
     */
    async deleteDelivery(requestParameters: DeleteDeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryDto> {
        const response = await this.deleteDeliveryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes delivery fee by currency for a delivery
     */
    async deleteDeliveryFeeRaw(requestParameters: DeleteDeliveryFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryFeeDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteDeliveryFee().'
            );
        }

        if (requestParameters['currency'] == null) {
            throw new runtime.RequiredError(
                'currency',
                'Required parameter "currency" was null or undefined when calling deleteDeliveryFee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/delivery/admin/fee/{id}/{currency}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"currency"}}`, encodeURIComponent(String(requestParameters['currency']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryFeeDtoFromJSON(jsonValue));
    }

    /**
     * Deletes delivery fee by currency for a delivery
     */
    async deleteDeliveryFee(requestParameters: DeleteDeliveryFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryFeeDto> {
        const response = await this.deleteDeliveryFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches all delivery records
     */
    async getAllDeliveriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliveryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/delivery`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliveryDtoFromJSON));
    }

    /**
     * Fetches all delivery records
     */
    async getAllDeliveries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliveryDto>> {
        const response = await this.getAllDeliveriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets delivery by ID
     */
    async getDeliveryByIdRaw(requestParameters: GetDeliveryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getDeliveryById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/delivery/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryDtoFromJSON(jsonValue));
    }

    /**
     * Gets delivery by ID
     */
    async getDeliveryById(requestParameters: GetDeliveryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryDto> {
        const response = await this.getDeliveryByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets delivery by type
     */
    async getDeliveryByTypeRaw(requestParameters: GetDeliveryByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryDto>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getDeliveryByType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/delivery/type/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryDtoFromJSON(jsonValue));
    }

    /**
     * Gets delivery by type
     */
    async getDeliveryByType(requestParameters: GetDeliveryByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryDto> {
        const response = await this.getDeliveryByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const DeleteDeliveryFeeCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type DeleteDeliveryFeeCurrencyEnum = typeof DeleteDeliveryFeeCurrencyEnum[keyof typeof DeleteDeliveryFeeCurrencyEnum];
/**
 * @export
 */
export const GetDeliveryByTypeTypeEnum = {
    Asap: 'ASAP',
    SameDay: 'SAME_DAY',
    NextDay: 'NEXT_DAY',
    InTwoDays: 'IN_TWO_DAYS'
} as const;
export type GetDeliveryByTypeTypeEnum = typeof GetDeliveryByTypeTypeEnum[keyof typeof GetDeliveryByTypeTypeEnum];
