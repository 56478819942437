/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterAdminUserDto
 */
export interface RegisterAdminUserDto {
    /**
     * FirstName
     * @type {string}
     * @memberof RegisterAdminUserDto
     */
    firstName: string;
    /**
     * Last name
     * @type {string}
     * @memberof RegisterAdminUserDto
     */
    lastName: string;
    /**
     * Email
     * @type {string}
     * @memberof RegisterAdminUserDto
     */
    email: string;
    /**
     * Phone number
     * @type {string}
     * @memberof RegisterAdminUserDto
     */
    phoneNumber: string;
    /**
     * Password with a minimum of 8 characters
     * @type {string}
     * @memberof RegisterAdminUserDto
     */
    password: string;
    /**
     * Verify password
     * @type {string}
     * @memberof RegisterAdminUserDto
     */
    passwordVerify: string;
}

/**
 * Check if a given object implements the RegisterAdminUserDto interface.
 */
export function instanceOfRegisterAdminUserDto(value: object): value is RegisterAdminUserDto {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('passwordVerify' in value) || value['passwordVerify'] === undefined) return false;
    return true;
}

export function RegisterAdminUserDtoFromJSON(json: any): RegisterAdminUserDto {
    return RegisterAdminUserDtoFromJSONTyped(json, false);
}

export function RegisterAdminUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterAdminUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'password': json['password'],
        'passwordVerify': json['passwordVerify'],
    };
}

export function RegisterAdminUserDtoToJSON(value?: RegisterAdminUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'password': value['password'],
        'passwordVerify': value['passwordVerify'],
    };
}

