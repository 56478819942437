/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Ping200ResponseInfoValue
 */
export interface Ping200ResponseInfoValue {
    [key: string]: object | any;
    /**
     * 
     * @type {string}
     * @memberof Ping200ResponseInfoValue
     */
    status: string;
}

/**
 * Check if a given object implements the Ping200ResponseInfoValue interface.
 */
export function instanceOfPing200ResponseInfoValue(value: object): value is Ping200ResponseInfoValue {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function Ping200ResponseInfoValueFromJSON(json: any): Ping200ResponseInfoValue {
    return Ping200ResponseInfoValueFromJSONTyped(json, false);
}

export function Ping200ResponseInfoValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ping200ResponseInfoValue {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'status': json['status'],
    };
}

export function Ping200ResponseInfoValueToJSON(value?: Ping200ResponseInfoValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'status': value['status'],
    };
}

