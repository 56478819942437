/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScrapBatteryDtoPrice } from './ScrapBatteryDtoPrice';
import {
    ScrapBatteryDtoPriceFromJSON,
    ScrapBatteryDtoPriceFromJSONTyped,
    ScrapBatteryDtoPriceToJSON,
} from './ScrapBatteryDtoPrice';

/**
 * 
 * @export
 * @interface ScrapBatteryDto
 */
export interface ScrapBatteryDto {
    /**
     * Scrap battery ID
     * @type {number}
     * @memberof ScrapBatteryDto
     */
    id: number;
    /**
     * Scrap battery quantity in Kg
     * @type {number}
     * @memberof ScrapBatteryDto
     */
    quantity: number;
    /**
     * 
     * @type {ScrapBatteryDtoPrice}
     * @memberof ScrapBatteryDto
     */
    price: ScrapBatteryDtoPrice;
    /**
     * Total price of scrap batteries. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof ScrapBatteryDto
     */
    totalPrice: number;
    /**
     * Timestamp scrap battery was created at
     * @type {Date}
     * @memberof ScrapBatteryDto
     */
    createdAt: Date;
    /**
     * Cart ID to which scrap battery records belong
     * @type {number}
     * @memberof ScrapBatteryDto
     */
    cartId: number;
    /**
     * Timestamp scrap battery was updated at
     * @type {Date}
     * @memberof ScrapBatteryDto
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the ScrapBatteryDto interface.
 */
export function instanceOfScrapBatteryDto(value: object): value is ScrapBatteryDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('totalPrice' in value) || value['totalPrice'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('cartId' in value) || value['cartId'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function ScrapBatteryDtoFromJSON(json: any): ScrapBatteryDto {
    return ScrapBatteryDtoFromJSONTyped(json, false);
}

export function ScrapBatteryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScrapBatteryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'quantity': json['quantity'],
        'price': ScrapBatteryDtoPriceFromJSON(json['price']),
        'totalPrice': json['totalPrice'],
        'createdAt': (new Date(json['createdAt'])),
        'cartId': json['cartId'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function ScrapBatteryDtoToJSON(value?: ScrapBatteryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'quantity': value['quantity'],
        'price': ScrapBatteryDtoPriceToJSON(value['price']),
        'totalPrice': value['totalPrice'],
        'createdAt': ((value['createdAt']).toISOString()),
        'cartId': value['cartId'],
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

