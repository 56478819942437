/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactInfoDto
 */
export interface ContactInfoDto {
    /**
     * Contact info record ID
     * @type {number}
     * @memberof ContactInfoDto
     */
    id: number;
    /**
     * Street name
     * @type {string}
     * @memberof ContactInfoDto
     */
    street: string;
    /**
     * Building number
     * @type {string}
     * @memberof ContactInfoDto
     */
    buildingNumber: string;
    /**
     * Administrative area 3 (area) fetched through the GeoService
     * @type {string}
     * @memberof ContactInfoDto
     */
    adminArea3: string | null;
    /**
     * Administrative area 3 code (area) fetched through the GeoService
     * @type {string}
     * @memberof ContactInfoDto
     */
    adminArea3Code: string | null;
    /**
     * Administrative area 2 (city) fetched through the GeoService
     * @type {string}
     * @memberof ContactInfoDto
     */
    adminArea2: string | null;
    /**
     * Administrative area 2 code (city) fetched through the GeoService
     * @type {string}
     * @memberof ContactInfoDto
     */
    adminArea2Code: string | null;
    /**
     * Administrative area 1 (state/province/governorate) fetched through the GeoService
     * @type {string}
     * @memberof ContactInfoDto
     */
    adminArea1: string | null;
    /**
     * Administrative area 1 code (state/province/governorate) fetched through the GeoService
     * @type {string}
     * @memberof ContactInfoDto
     */
    adminArea1Code: string | null;
    /**
     * Geocoding Country name
     * @type {string}
     * @memberof ContactInfoDto
     */
    country: string | null;
    /**
     * Geocoding Country ISO code
     * @type {string}
     * @memberof ContactInfoDto
     */
    countryCode: string | null;
    /**
     * Geocoding Location postal code
     * @type {string}
     * @memberof ContactInfoDto
     */
    postalCode: string | null;
    /**
     * Latitude
     * @type {number}
     * @memberof ContactInfoDto
     */
    latitude: number;
    /**
     * Longitude
     * @type {number}
     * @memberof ContactInfoDto
     */
    longitude: number;
    /**
     * Formatted address returned from reverse geocoding of latitude and longitude
     * @type {string}
     * @memberof ContactInfoDto
     */
    formattedAddress: string;
    /**
     * Navigation hints and remarks to facilitate reaching the address
     * @type {string}
     * @memberof ContactInfoDto
     */
    navDirections: string | null;
    /**
     * Location phone number
     * @type {string}
     * @memberof ContactInfoDto
     */
    phoneNumber: string;
    /**
     * Location email
     * @type {string}
     * @memberof ContactInfoDto
     */
    email: string | null;
    /**
     * Branch ID
     * @type {number}
     * @memberof ContactInfoDto
     */
    branchId?: number;
    /**
     * Organization ID
     * @type {number}
     * @memberof ContactInfoDto
     */
    organizationId?: number;
    /**
     * Timestamp contact info record was created at
     * @type {Date}
     * @memberof ContactInfoDto
     */
    createdAt?: Date;
    /**
     * Timestamp contact info record was updated at
     * @type {Date}
     * @memberof ContactInfoDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the ContactInfoDto interface.
 */
export function instanceOfContactInfoDto(value: object): value is ContactInfoDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('street' in value) || value['street'] === undefined) return false;
    if (!('buildingNumber' in value) || value['buildingNumber'] === undefined) return false;
    if (!('adminArea3' in value) || value['adminArea3'] === undefined) return false;
    if (!('adminArea3Code' in value) || value['adminArea3Code'] === undefined) return false;
    if (!('adminArea2' in value) || value['adminArea2'] === undefined) return false;
    if (!('adminArea2Code' in value) || value['adminArea2Code'] === undefined) return false;
    if (!('adminArea1' in value) || value['adminArea1'] === undefined) return false;
    if (!('adminArea1Code' in value) || value['adminArea1Code'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('countryCode' in value) || value['countryCode'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    if (!('formattedAddress' in value) || value['formattedAddress'] === undefined) return false;
    if (!('navDirections' in value) || value['navDirections'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function ContactInfoDtoFromJSON(json: any): ContactInfoDto {
    return ContactInfoDtoFromJSONTyped(json, false);
}

export function ContactInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'street': json['street'],
        'buildingNumber': json['buildingNumber'],
        'adminArea3': json['adminArea3'],
        'adminArea3Code': json['adminArea3Code'],
        'adminArea2': json['adminArea2'],
        'adminArea2Code': json['adminArea2Code'],
        'adminArea1': json['adminArea1'],
        'adminArea1Code': json['adminArea1Code'],
        'country': json['country'],
        'countryCode': json['countryCode'],
        'postalCode': json['postalCode'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'formattedAddress': json['formattedAddress'],
        'navDirections': json['navDirections'],
        'phoneNumber': json['phoneNumber'],
        'email': json['email'],
        'branchId': json['branchId'] == null ? undefined : json['branchId'],
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
    };
}

export function ContactInfoDtoToJSON(value?: ContactInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'street': value['street'],
        'buildingNumber': value['buildingNumber'],
        'adminArea3': value['adminArea3'],
        'adminArea3Code': value['adminArea3Code'],
        'adminArea2': value['adminArea2'],
        'adminArea2Code': value['adminArea2Code'],
        'adminArea1': value['adminArea1'],
        'adminArea1Code': value['adminArea1Code'],
        'country': value['country'],
        'countryCode': value['countryCode'],
        'postalCode': value['postalCode'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'formattedAddress': value['formattedAddress'],
        'navDirections': value['navDirections'],
        'phoneNumber': value['phoneNumber'],
        'email': value['email'],
        'branchId': value['branchId'],
        'organizationId': value['organizationId'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

