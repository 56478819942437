import type { CustomErrorResponseDto, Middleware, ResponseContext } from '@/services';
import { useAlertStore } from '@/stores';

export class ErrorHandlingMiddleware implements Middleware {
  private readonly excludedPaths: string[] = ['/areeb-auth/authenticate'];

  public async post(context: ResponseContext): Promise<Response | void> {
    const alertStore = useAlertStore();

    const statusCode = context.response.status;
    const isSuccessStatusCode = statusCode >= 200 && statusCode < 300;
    const isPathExcluded = this.isPathExcluded(context.url);

    if (!isSuccessStatusCode && !isPathExcluded) {
      alertStore.setErrorResponse((await context.response.json()) as unknown as CustomErrorResponseDto);
    }

    return Promise.resolve(context.response);
  }

  private isPathExcluded(path: string): boolean {
    return this.excludedPaths.some((excludedPath) => path.includes(excludedPath));
  }
}
