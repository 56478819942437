/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DeliveryDto } from './DeliveryDto';
import {
    DeliveryDtoFromJSON,
    DeliveryDtoFromJSONTyped,
    DeliveryDtoToJSON,
} from './DeliveryDto';
import type { CartItemDto } from './CartItemDto';
import {
    CartItemDtoFromJSON,
    CartItemDtoFromJSONTyped,
    CartItemDtoToJSON,
} from './CartItemDto';
import type { ScrapBatteryDto } from './ScrapBatteryDto';
import {
    ScrapBatteryDtoFromJSON,
    ScrapBatteryDtoFromJSONTyped,
    ScrapBatteryDtoToJSON,
} from './ScrapBatteryDto';

/**
 * User cart
 * @export
 * @interface UserDtoCart
 */
export interface UserDtoCart {
    /**
     * Cart ID
     * @type {number}
     * @memberof UserDtoCart
     */
    id: number;
    /**
     * User ID
     * @type {string}
     * @memberof UserDtoCart
     */
    userId: string;
    /**
     * List of items in cart
     * @type {Array<CartItemDto>}
     * @memberof UserDtoCart
     */
    items?: Array<CartItemDto>;
    /**
     * Delivery
     * @type {DeliveryDto}
     * @memberof UserDtoCart
     */
    delivery?: DeliveryDto;
    /**
     * Delivery ID
     * @type {number}
     * @memberof UserDtoCart
     */
    deliveryId?: number;
    /**
     * Desired payment option
     * @type {string}
     * @memberof UserDtoCart
     */
    paymentOption?: UserDtoCartPaymentOptionEnum;
    /**
     * Scrap battery
     * @type {ScrapBatteryDto}
     * @memberof UserDtoCart
     */
    scrapBattery?: ScrapBatteryDto;
    /**
     * Total price of the cart items. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof UserDtoCart
     */
    totalPrice?: number;
    /**
     * Total tax of the cart items. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof UserDtoCart
     */
    totalTax?: number;
    /**
     * Total discount of the cart items. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof UserDtoCart
     */
    totalDiscount?: number;
    /**
     * Total volume of the cart items. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof UserDtoCart
     */
    totalVolume?: number;
    /**
     * Total footprint of the cart items. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof UserDtoCart
     */
    totalFootprint?: number;
    /**
     * Total weight of the cart items. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof UserDtoCart
     */
    totalWeight?: number;
    /**
     * Timestamp cart was created at
     * @type {Date}
     * @memberof UserDtoCart
     */
    createdAt?: Date;
    /**
     * Timestamp cart was updated at
     * @type {Date}
     * @memberof UserDtoCart
     */
    updatedAt?: Date;
}


/**
 * @export
 */
export const UserDtoCartPaymentOptionEnum = {
    Cod: 'COD'
} as const;
export type UserDtoCartPaymentOptionEnum = typeof UserDtoCartPaymentOptionEnum[keyof typeof UserDtoCartPaymentOptionEnum];


/**
 * Check if a given object implements the UserDtoCart interface.
 */
export function instanceOfUserDtoCart(value: object): value is UserDtoCart {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    return true;
}

export function UserDtoCartFromJSON(json: any): UserDtoCart {
    return UserDtoCartFromJSONTyped(json, false);
}

export function UserDtoCartFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDtoCart {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(CartItemDtoFromJSON)),
        'delivery': json['delivery'] == null ? undefined : DeliveryDtoFromJSON(json['delivery']),
        'deliveryId': json['deliveryId'] == null ? undefined : json['deliveryId'],
        'paymentOption': json['paymentOption'] == null ? undefined : json['paymentOption'],
        'scrapBattery': json['scrapBattery'] == null ? undefined : ScrapBatteryDtoFromJSON(json['scrapBattery']),
        'totalPrice': json['totalPrice'] == null ? undefined : json['totalPrice'],
        'totalTax': json['totalTax'] == null ? undefined : json['totalTax'],
        'totalDiscount': json['totalDiscount'] == null ? undefined : json['totalDiscount'],
        'totalVolume': json['totalVolume'] == null ? undefined : json['totalVolume'],
        'totalFootprint': json['totalFootprint'] == null ? undefined : json['totalFootprint'],
        'totalWeight': json['totalWeight'] == null ? undefined : json['totalWeight'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
    };
}

export function UserDtoCartToJSON(value?: UserDtoCart | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'userId': value['userId'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(CartItemDtoToJSON)),
        'delivery': DeliveryDtoToJSON(value['delivery']),
        'deliveryId': value['deliveryId'],
        'paymentOption': value['paymentOption'],
        'scrapBattery': ScrapBatteryDtoToJSON(value['scrapBattery']),
        'totalPrice': value['totalPrice'],
        'totalTax': value['totalTax'],
        'totalDiscount': value['totalDiscount'],
        'totalVolume': value['totalVolume'],
        'totalFootprint': value['totalFootprint'],
        'totalWeight': value['totalWeight'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

