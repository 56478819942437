/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderItemDto } from './OrderItemDto';
import {
    OrderItemDtoFromJSON,
    OrderItemDtoFromJSONTyped,
    OrderItemDtoToJSON,
} from './OrderItemDto';

/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * Order ID
     * @type {number}
     * @memberof OrderDto
     */
    id: number;
    /**
     * Order number
     * @type {string}
     * @memberof OrderDto
     */
    orderNumber: string;
    /**
     * Order status
     * @type {number}
     * @memberof OrderDto
     */
    status: number;
    /**
     * Delivery type specified by customer
     * @type {number}
     * @memberof OrderDto
     */
    deliveryType: number;
    /**
     * Delivery fee
     * @type {number}
     * @memberof OrderDto
     */
    deliveryFee: number;
    /**
     * Timestamp at which the order is expected to be delivered
     * @type {Date}
     * @memberof OrderDto
     */
    estDeliveryAt: Date;
    /**
     * Administrative area 3 (area) fetched through the GeoService
     * @type {string}
     * @memberof OrderDto
     */
    adminArea3: string | null;
    /**
     * Administrative area 3 code (area) fetched through the GeoService
     * @type {string}
     * @memberof OrderDto
     */
    adminArea3Code: string | null;
    /**
     * Administrative area 2 (city) fetched through the GeoService
     * @type {string}
     * @memberof OrderDto
     */
    adminArea2: string | null;
    /**
     * Administrative area 2 code (city) fetched through the GeoService
     * @type {string}
     * @memberof OrderDto
     */
    adminArea2Code: string | null;
    /**
     * Administrative area 1 (state/province/governorate) fetched through the GeoService
     * @type {string}
     * @memberof OrderDto
     */
    adminArea1: string | null;
    /**
     * Administrative area 1 code (state/province/governorate) fetched through the GeoService
     * @type {string}
     * @memberof OrderDto
     */
    adminArea1Code: string | null;
    /**
     * Geocoding Country name
     * @type {string}
     * @memberof OrderDto
     */
    country: string | null;
    /**
     * Geocoding Country ISO code
     * @type {string}
     * @memberof OrderDto
     */
    countryCode: string | null;
    /**
     * Geocoding Location postal code
     * @type {string}
     * @memberof OrderDto
     */
    postalCode: string | null;
    /**
     * Formatted address returned from google maps reverse geocoding of latitude and longitude
     * @type {string}
     * @memberof OrderDto
     */
    formattedAddress: string | null;
    /**
     * Navigation hints and remarks to facilitate reaching the address
     * @type {string}
     * @memberof OrderDto
     */
    navDirections: string | null;
    /**
     * Location phone number
     * @type {string}
     * @memberof OrderDto
     */
    phoneNumber: string;
    /**
     * Branch name
     * @type {string}
     * @memberof OrderDto
     */
    branchName: string;
    /**
     * Order items
     * @type {Array<OrderItemDto>}
     * @memberof OrderDto
     */
    items: Array<OrderItemDto>;
    /**
     * Order currency based on user active branch currency at the time of order creation
     * @type {string}
     * @memberof OrderDto
     */
    currency: OrderDtoCurrencyEnum;
    /**
     * Total price of order items
     * @type {number}
     * @memberof OrderDto
     */
    totalPrice: number;
    /**
     * Total tax of order items
     * @type {number}
     * @memberof OrderDto
     */
    totalTax: number | null;
    /**
     * Total discount of order items
     * @type {number}
     * @memberof OrderDto
     */
    totalDiscount: number | null;
    /**
     * Total volume of order items
     * @type {number}
     * @memberof OrderDto
     */
    totalVolume: number | null;
    /**
     * Total area which order items would occupy
     * @type {number}
     * @memberof OrderDto
     */
    totalFootprint: number | null;
    /**
     * Total weight of order items
     * @type {number}
     * @memberof OrderDto
     */
    totalWeight: number | null;
    /**
     * Total item quantity of order items
     * @type {number}
     * @memberof OrderDto
     */
    totalQuantity: number;
    /**
     * Scrap battery quantity in Kg
     * @type {number}
     * @memberof OrderDto
     */
    scrapBatteryQuantity?: number;
    /**
     * Scrap battery unit price
     * @type {number}
     * @memberof OrderDto
     */
    scrapBatteryUnitPrice?: number;
    /**
     * Scrap battery total price
     * @type {number}
     * @memberof OrderDto
     */
    scrapBatteryTotalPrice?: number;
    /**
     * Branch ID
     * @type {number}
     * @memberof OrderDto
     */
    branchId: number;
    /**
     * User ID
     * @type {string}
     * @memberof OrderDto
     */
    userId: string;
    /**
     * Timestamp order was created at
     * @type {Date}
     * @memberof OrderDto
     */
    createdAt: Date;
    /**
     * Timestamp order was shipped to customer at
     * @type {Date}
     * @memberof OrderDto
     */
    shippedAt: Date | null;
    /**
     * Timestamp order was delivered at
     * @type {Date}
     * @memberof OrderDto
     */
    deliveredAt: Date | null;
    /**
     * Timestamp order was delivered at
     * @type {Date}
     * @memberof OrderDto
     */
    cancelledAt: Date | null;
    /**
     * Timestamp order was delivered at
     * @type {Date}
     * @memberof OrderDto
     */
    processedAt: Date | null;
    /**
     * Timestamp order was set to pending at
     * @type {Date}
     * @memberof OrderDto
     */
    pendingAt: Date | null;
    /**
     * Timestamp order was updated at
     * @type {Date}
     * @memberof OrderDto
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const OrderDtoCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type OrderDtoCurrencyEnum = typeof OrderDtoCurrencyEnum[keyof typeof OrderDtoCurrencyEnum];


/**
 * Check if a given object implements the OrderDto interface.
 */
export function instanceOfOrderDto(value: object): value is OrderDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('orderNumber' in value) || value['orderNumber'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('deliveryType' in value) || value['deliveryType'] === undefined) return false;
    if (!('deliveryFee' in value) || value['deliveryFee'] === undefined) return false;
    if (!('estDeliveryAt' in value) || value['estDeliveryAt'] === undefined) return false;
    if (!('adminArea3' in value) || value['adminArea3'] === undefined) return false;
    if (!('adminArea3Code' in value) || value['adminArea3Code'] === undefined) return false;
    if (!('adminArea2' in value) || value['adminArea2'] === undefined) return false;
    if (!('adminArea2Code' in value) || value['adminArea2Code'] === undefined) return false;
    if (!('adminArea1' in value) || value['adminArea1'] === undefined) return false;
    if (!('adminArea1Code' in value) || value['adminArea1Code'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('countryCode' in value) || value['countryCode'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    if (!('formattedAddress' in value) || value['formattedAddress'] === undefined) return false;
    if (!('navDirections' in value) || value['navDirections'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('branchName' in value) || value['branchName'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('totalPrice' in value) || value['totalPrice'] === undefined) return false;
    if (!('totalTax' in value) || value['totalTax'] === undefined) return false;
    if (!('totalDiscount' in value) || value['totalDiscount'] === undefined) return false;
    if (!('totalVolume' in value) || value['totalVolume'] === undefined) return false;
    if (!('totalFootprint' in value) || value['totalFootprint'] === undefined) return false;
    if (!('totalWeight' in value) || value['totalWeight'] === undefined) return false;
    if (!('totalQuantity' in value) || value['totalQuantity'] === undefined) return false;
    if (!('branchId' in value) || value['branchId'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('shippedAt' in value) || value['shippedAt'] === undefined) return false;
    if (!('deliveredAt' in value) || value['deliveredAt'] === undefined) return false;
    if (!('cancelledAt' in value) || value['cancelledAt'] === undefined) return false;
    if (!('processedAt' in value) || value['processedAt'] === undefined) return false;
    if (!('pendingAt' in value) || value['pendingAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function OrderDtoFromJSON(json: any): OrderDto {
    return OrderDtoFromJSONTyped(json, false);
}

export function OrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderNumber': json['orderNumber'],
        'status': json['status'],
        'deliveryType': json['deliveryType'],
        'deliveryFee': json['deliveryFee'],
        'estDeliveryAt': (new Date(json['estDeliveryAt'])),
        'adminArea3': json['adminArea3'],
        'adminArea3Code': json['adminArea3Code'],
        'adminArea2': json['adminArea2'],
        'adminArea2Code': json['adminArea2Code'],
        'adminArea1': json['adminArea1'],
        'adminArea1Code': json['adminArea1Code'],
        'country': json['country'],
        'countryCode': json['countryCode'],
        'postalCode': json['postalCode'],
        'formattedAddress': json['formattedAddress'],
        'navDirections': json['navDirections'],
        'phoneNumber': json['phoneNumber'],
        'branchName': json['branchName'],
        'items': ((json['items'] as Array<any>).map(OrderItemDtoFromJSON)),
        'currency': json['currency'],
        'totalPrice': json['totalPrice'],
        'totalTax': json['totalTax'],
        'totalDiscount': json['totalDiscount'],
        'totalVolume': json['totalVolume'],
        'totalFootprint': json['totalFootprint'],
        'totalWeight': json['totalWeight'],
        'totalQuantity': json['totalQuantity'],
        'scrapBatteryQuantity': json['scrapBatteryQuantity'] == null ? undefined : json['scrapBatteryQuantity'],
        'scrapBatteryUnitPrice': json['scrapBatteryUnitPrice'] == null ? undefined : json['scrapBatteryUnitPrice'],
        'scrapBatteryTotalPrice': json['scrapBatteryTotalPrice'] == null ? undefined : json['scrapBatteryTotalPrice'],
        'branchId': json['branchId'],
        'userId': json['userId'],
        'createdAt': (new Date(json['createdAt'])),
        'shippedAt': (json['shippedAt'] == null ? null : new Date(json['shippedAt'])),
        'deliveredAt': (json['deliveredAt'] == null ? null : new Date(json['deliveredAt'])),
        'cancelledAt': (json['cancelledAt'] == null ? null : new Date(json['cancelledAt'])),
        'processedAt': (json['processedAt'] == null ? null : new Date(json['processedAt'])),
        'pendingAt': (json['pendingAt'] == null ? null : new Date(json['pendingAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function OrderDtoToJSON(value?: OrderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'orderNumber': value['orderNumber'],
        'status': value['status'],
        'deliveryType': value['deliveryType'],
        'deliveryFee': value['deliveryFee'],
        'estDeliveryAt': ((value['estDeliveryAt']).toISOString()),
        'adminArea3': value['adminArea3'],
        'adminArea3Code': value['adminArea3Code'],
        'adminArea2': value['adminArea2'],
        'adminArea2Code': value['adminArea2Code'],
        'adminArea1': value['adminArea1'],
        'adminArea1Code': value['adminArea1Code'],
        'country': value['country'],
        'countryCode': value['countryCode'],
        'postalCode': value['postalCode'],
        'formattedAddress': value['formattedAddress'],
        'navDirections': value['navDirections'],
        'phoneNumber': value['phoneNumber'],
        'branchName': value['branchName'],
        'items': ((value['items'] as Array<any>).map(OrderItemDtoToJSON)),
        'currency': value['currency'],
        'totalPrice': value['totalPrice'],
        'totalTax': value['totalTax'],
        'totalDiscount': value['totalDiscount'],
        'totalVolume': value['totalVolume'],
        'totalFootprint': value['totalFootprint'],
        'totalWeight': value['totalWeight'],
        'totalQuantity': value['totalQuantity'],
        'scrapBatteryQuantity': value['scrapBatteryQuantity'],
        'scrapBatteryUnitPrice': value['scrapBatteryUnitPrice'],
        'scrapBatteryTotalPrice': value['scrapBatteryTotalPrice'],
        'branchId': value['branchId'],
        'userId': value['userId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'shippedAt': (value['shippedAt'] == null ? null : (value['shippedAt'] as any).toISOString()),
        'deliveredAt': (value['deliveredAt'] == null ? null : (value['deliveredAt'] as any).toISOString()),
        'cancelledAt': (value['cancelledAt'] == null ? null : (value['cancelledAt'] as any).toISOString()),
        'processedAt': (value['processedAt'] == null ? null : (value['processedAt'] as any).toISOString()),
        'pendingAt': (value['pendingAt'] == null ? null : (value['pendingAt'] as any).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

