/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DeliveryDtoDeliveryFee } from './DeliveryDtoDeliveryFee';
import {
    DeliveryDtoDeliveryFeeFromJSON,
    DeliveryDtoDeliveryFeeFromJSONTyped,
    DeliveryDtoDeliveryFeeToJSON,
} from './DeliveryDtoDeliveryFee';

/**
 * 
 * @export
 * @interface DeliveryDto
 */
export interface DeliveryDto {
    /**
     * Delivery ID
     * @type {number}
     * @memberof DeliveryDto
     */
    id: number;
    /**
     * Delivery type
     * @type {string}
     * @memberof DeliveryDto
     */
    type: DeliveryDtoTypeEnum;
    /**
     * Delivery description in English
     * @type {number}
     * @memberof DeliveryDto
     */
    descriptionEn?: number;
    /**
     * Delivery description in Arabic
     * @type {number}
     * @memberof DeliveryDto
     */
    descriptionAr?: number;
    /**
     * 
     * @type {DeliveryDtoDeliveryFee}
     * @memberof DeliveryDto
     */
    deliveryFee: DeliveryDtoDeliveryFee;
    /**
     * Timestamp delivery was created at
     * @type {Date}
     * @memberof DeliveryDto
     */
    createdAt: Date;
    /**
     * Timestamp delivery was updated at
     * @type {Date}
     * @memberof DeliveryDto
     */
    updatedAt: Date;
}


/**
 * @export
 */
export const DeliveryDtoTypeEnum = {
    Asap: 'ASAP',
    SameDay: 'SAME_DAY',
    NextDay: 'NEXT_DAY',
    InTwoDays: 'IN_TWO_DAYS'
} as const;
export type DeliveryDtoTypeEnum = typeof DeliveryDtoTypeEnum[keyof typeof DeliveryDtoTypeEnum];


/**
 * Check if a given object implements the DeliveryDto interface.
 */
export function instanceOfDeliveryDto(value: object): value is DeliveryDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('deliveryFee' in value) || value['deliveryFee'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function DeliveryDtoFromJSON(json: any): DeliveryDto {
    return DeliveryDtoFromJSONTyped(json, false);
}

export function DeliveryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'descriptionEn': json['descriptionEn'] == null ? undefined : json['descriptionEn'],
        'descriptionAr': json['descriptionAr'] == null ? undefined : json['descriptionAr'],
        'deliveryFee': DeliveryDtoDeliveryFeeFromJSON(json['deliveryFee']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function DeliveryDtoToJSON(value?: DeliveryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'type': value['type'],
        'descriptionEn': value['descriptionEn'],
        'descriptionAr': value['descriptionAr'],
        'deliveryFee': DeliveryDtoDeliveryFeeToJSON(value['deliveryFee']),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

