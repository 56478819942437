/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @type ScrapBatteryDtoPrice
 * Scrap battery price per Kg
 * @export
 */
export type ScrapBatteryDtoPrice = Array<ScrapBatteryPriceDto> | number;

export function ScrapBatteryDtoPriceFromJSON(json: any): ScrapBatteryDtoPrice {
    return ScrapBatteryDtoPriceFromJSONTyped(json, false);
}

export function ScrapBatteryDtoPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScrapBatteryDtoPrice {
    if (json == null) {
        return json;
    }
    if (instanceOfArray<ScrapBatteryPriceDto>(json)) {
        return Array<ScrapBatteryPriceDto>FromJSONTyped(json, true);
    }
    if (instanceOfnumber(json)) {
        return numberFromJSONTyped(json, true);
    }
}

export function ScrapBatteryDtoPriceToJSON(value?: ScrapBatteryDtoPrice | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfArray<ScrapBatteryPriceDto>(value)) {
        return Array<ScrapBatteryPriceDto>ToJSON(value as Array<ScrapBatteryPriceDto>);
    }
    if (instanceOfnumber(value)) {
        return numberToJSON(value as number);
    }

    return {};
}

