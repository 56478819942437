import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export interface ISidebarState {
  isSidebarOpen: boolean;
  selected: string;
  page: string;
}

export const useSidebarStore = defineStore({
  id: 'sidebar',
  state: (): ISidebarState => ({
    isSidebarOpen: false,
    selected: useStorage('selected', '').value,
    page: useStorage('page', 'orders').value,
  }),
  actions: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    setSelected(value: string) {
      this.selected = value;
    },
    setPage(value: string) {
      this.page = value;
    },
  },
});
