/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderItemDto
 */
export interface OrderItemDto {
    /**
     * Order item ID
     * @type {number}
     * @memberof OrderItemDto
     */
    id: number;
    /**
     * Product unit price
     * @type {number}
     * @memberof OrderItemDto
     */
    unitPrice: number;
    /**
     * Order item price
     * @type {number}
     * @memberof OrderItemDto
     */
    price: number;
    /**
     * Order item tax
     * @type {number}
     * @memberof OrderItemDto
     */
    tax: number;
    /**
     * Order item tax
     * @type {number}
     * @memberof OrderItemDto
     */
    discount: number | null;
    /**
     * Order item product quantity
     * @type {number}
     * @memberof OrderItemDto
     */
    quantity: number;
    /**
     * Order item volume
     * @type {number}
     * @memberof OrderItemDto
     */
    volume: number | null;
    /**
     * Order item footprint
     * @type {number}
     * @memberof OrderItemDto
     */
    footprint: number | null;
    /**
     * Order item weight
     * @type {number}
     * @memberof OrderItemDto
     */
    weight: number | null;
    /**
     * Product
     * @type {number}
     * @memberof OrderItemDto
     */
    product?: number;
    /**
     * Product ID
     * @type {number}
     * @memberof OrderItemDto
     */
    productId: number;
    /**
     * Product name
     * @type {string}
     * @memberof OrderItemDto
     */
    productName: string;
    /**
     * Product image url
     * @type {string}
     * @memberof OrderItemDto
     */
    productImageUrl: string;
    /**
     * Product ID
     * @type {number}
     * @memberof OrderItemDto
     */
    orderId: number;
    /**
     * Timestamp order item was created at
     * @type {Date}
     * @memberof OrderItemDto
     */
    createdAt: Date;
    /**
     * Timestamp order item was updated at
     * @type {Date}
     * @memberof OrderItemDto
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the OrderItemDto interface.
 */
export function instanceOfOrderItemDto(value: object): value is OrderItemDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('unitPrice' in value) || value['unitPrice'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('tax' in value) || value['tax'] === undefined) return false;
    if (!('discount' in value) || value['discount'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    if (!('volume' in value) || value['volume'] === undefined) return false;
    if (!('footprint' in value) || value['footprint'] === undefined) return false;
    if (!('weight' in value) || value['weight'] === undefined) return false;
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('productName' in value) || value['productName'] === undefined) return false;
    if (!('productImageUrl' in value) || value['productImageUrl'] === undefined) return false;
    if (!('orderId' in value) || value['orderId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function OrderItemDtoFromJSON(json: any): OrderItemDto {
    return OrderItemDtoFromJSONTyped(json, false);
}

export function OrderItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'unitPrice': json['unitPrice'],
        'price': json['price'],
        'tax': json['tax'],
        'discount': json['discount'],
        'quantity': json['quantity'],
        'volume': json['volume'],
        'footprint': json['footprint'],
        'weight': json['weight'],
        'product': json['product'] == null ? undefined : json['product'],
        'productId': json['productId'],
        'productName': json['productName'],
        'productImageUrl': json['productImageUrl'],
        'orderId': json['orderId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function OrderItemDtoToJSON(value?: OrderItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'unitPrice': value['unitPrice'],
        'price': value['price'],
        'tax': value['tax'],
        'discount': value['discount'],
        'quantity': value['quantity'],
        'volume': value['volume'],
        'footprint': value['footprint'],
        'weight': value['weight'],
        'product': value['product'],
        'productId': value['productId'],
        'productName': value['productName'],
        'productImageUrl': value['productImageUrl'],
        'orderId': value['orderId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
    };
}

