/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Ping200ResponseInfoValue } from './Ping200ResponseInfoValue';
import {
    Ping200ResponseInfoValueFromJSON,
    Ping200ResponseInfoValueFromJSONTyped,
    Ping200ResponseInfoValueToJSON,
} from './Ping200ResponseInfoValue';

/**
 * 
 * @export
 * @interface Ping200Response
 */
export interface Ping200Response {
    /**
     * 
     * @type {string}
     * @memberof Ping200Response
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: Ping200ResponseInfoValue; }}
     * @memberof Ping200Response
     */
    info?: { [key: string]: Ping200ResponseInfoValue; };
    /**
     * 
     * @type {{ [key: string]: Ping200ResponseInfoValue; }}
     * @memberof Ping200Response
     */
    error?: { [key: string]: Ping200ResponseInfoValue; };
    /**
     * 
     * @type {{ [key: string]: Ping200ResponseInfoValue; }}
     * @memberof Ping200Response
     */
    details?: { [key: string]: Ping200ResponseInfoValue; };
}

/**
 * Check if a given object implements the Ping200Response interface.
 */
export function instanceOfPing200Response(value: object): value is Ping200Response {
    return true;
}

export function Ping200ResponseFromJSON(json: any): Ping200Response {
    return Ping200ResponseFromJSONTyped(json, false);
}

export function Ping200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ping200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
        'info': json['info'] == null ? undefined : (mapValues(json['info'], Ping200ResponseInfoValueFromJSON)),
        'error': json['error'] == null ? undefined : (mapValues(json['error'], Ping200ResponseInfoValueFromJSON)),
        'details': json['details'] == null ? undefined : (mapValues(json['details'], Ping200ResponseInfoValueFromJSON)),
    };
}

export function Ping200ResponseToJSON(value?: Ping200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'info': value['info'] == null ? undefined : (mapValues(value['info'], Ping200ResponseInfoValueToJSON)),
        'error': value['error'] == null ? undefined : (mapValues(value['error'], Ping200ResponseInfoValueToJSON)),
        'details': value['details'] == null ? undefined : (mapValues(value['details'], Ping200ResponseInfoValueToJSON)),
    };
}

