/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAdminUserDto
 */
export interface UpdateAdminUserDto {
    /**
     * FirstName
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    firstName?: string;
    /**
     * Last name
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    lastName?: string;
    /**
     * Email
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    email?: string;
    /**
     * Phone number
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    phoneNumber?: string;
    /**
     * Password with a minimum of 8 characters
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    password?: string;
    /**
     * Admin user roles
     * @type {Array<number>}
     * @memberof UpdateAdminUserDto
     */
    roles?: Array<number>;
    /**
     * Password with a minimum of 8 characters
     * @type {string}
     * @memberof UpdateAdminUserDto
     */
    isConfirmed?: string;
}

/**
 * Check if a given object implements the UpdateAdminUserDto interface.
 */
export function instanceOfUpdateAdminUserDto(value: object): value is UpdateAdminUserDto {
    return true;
}

export function UpdateAdminUserDtoFromJSON(json: any): UpdateAdminUserDto {
    return UpdateAdminUserDtoFromJSONTyped(json, false);
}

export function UpdateAdminUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAdminUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'password': json['password'] == null ? undefined : json['password'],
        'roles': json['roles'] == null ? undefined : json['roles'],
        'isConfirmed': json['isConfirmed'] == null ? undefined : json['isConfirmed'],
    };
}

export function UpdateAdminUserDtoToJSON(value?: UpdateAdminUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'password': value['password'],
        'roles': value['roles'],
        'isConfirmed': value['isConfirmed'],
    };
}

