import { defineStore } from 'pinia';
import type { OrderDto, OrdersResponseDto, UpdateOrderDto } from '@/services';
import { useApiStore } from '@/stores/api.store';
import { useDebugStore } from '@/stores/debug.store';
import type { OrdersSearchOptions } from '@/interfaces';
import { usePageLoaderStore } from '@/stores/page-loader.store';

export interface IOrdersState {
  orders: OrderDto[];
  order: OrderDto | undefined;
  currentPage: number;
  totalPages: number | undefined;
}

export const useOrderStore = defineStore({
  id: 'order',
  state: (): IOrdersState => ({
    orders: [],
    order: undefined,
    currentPage: 1,
    totalPages: undefined,
  }),
  actions: {
    async fetchOrders(options: OrdersSearchOptions, pageSize = 10): Promise<Promise<OrdersResponseDto> | undefined> {
      const pageLoaderStore = usePageLoaderStore();
      pageLoaderStore.setLoading(true);

      const api = useApiStore();

      try {
        const response: OrdersResponseDto = await api.OrderApi.getOrders({
          ...options,
          page: this.currentPage,
          pageSize,
        });

        if (this.currentPage && response.totalPages && this.currentPage > response.totalPages) {
          this.currentPage = response.totalPages;
          pageLoaderStore.setLoading(false);

          return this.fetchOrders(options);
        }

        this.totalPages = response.totalPages;
        this.orders = [...response.data];

        pageLoaderStore.setLoading(false);
        return (response || {}) as OrdersResponseDto;
      } catch (e) {
        const debugStore = useDebugStore();
        debugStore.printDebugInfo('Failed to fetch orders | ', e);
      }
    },
    async getOrderById(orderId: string) {
      const pageLoaderStore = usePageLoaderStore();
      pageLoaderStore.setLoading(true);

      const api = useApiStore();

      try {
        const response: OrderDto = await api.OrderApi.getOrderById({ id: orderId });

        this.order = response;
        pageLoaderStore.setLoading(false);
      } catch (e) {
        const debugStore = useDebugStore();
        debugStore.printDebugInfo(`Failed to fetch order with ID ${orderId} | `, e);
      }
    },
    async updateOrder(orderId: string, updateOrderDto: UpdateOrderDto) {
      const pageLoaderStore = usePageLoaderStore();
      pageLoaderStore.setLoading(true);

      const api = useApiStore();

      try {
        const response: OrderDto = await api.OrderApi.updateOrder({ id: orderId, updateOrderDto });

        this.order = response;
        pageLoaderStore.setLoading(false);
      } catch (e) {
        const debugStore = useDebugStore();
        debugStore.printDebugInfo(`Failed to update order with ID ${orderId} | `, e);
      }
    },
  },
});
