import { defineStore } from 'pinia';
import {
  type AdminUserDto,
  AdminUserServiceApi,
  AppServiceApi,
  AreebAuthServiceApi,
  BranchServiceApi,
  Configuration,
  DeliveryServiceApi,
  EmailServiceApi,
  OrderServiceApi,
  ProductServiceApi,
  ScrapBatteryPriceServiceApi,
  UserServiceApi,
} from '@/services';
import { ErrorHandlingMiddleware } from '@/middleware';
import { useDebugStore } from '@/stores/debug.store';
import { API_URL } from '@/config';

export interface IApiState {
  currentUser: AdminUserDto | null;
  accessToken: string;
  apiConfig: Configuration | undefined;
}

export const useApiStore = defineStore({
  id: 'api',
  state: (): IApiState => ({
    currentUser: null,
    accessToken: '',
    apiConfig: new Configuration({
      basePath: API_URL,
      fetchApi: fetch,
      middleware: [new ErrorHandlingMiddleware()],
    }),
  }),
  getters: {
    AdminUserApi(): AdminUserServiceApi {
      return new AdminUserServiceApi(this.apiConfig as Configuration);
    },
    AppApi(): AppServiceApi {
      return new AppServiceApi(this.apiConfig as Configuration);
    },
    AuthApi(): AreebAuthServiceApi {
      return new AreebAuthServiceApi(this.apiConfig as Configuration);
    },
    BranchApi(): BranchServiceApi {
      return new BranchServiceApi(this.apiConfig as Configuration);
    },
    DeliveryApi(): DeliveryServiceApi {
      return new DeliveryServiceApi(this.apiConfig as Configuration);
    },
    EmailApi(): EmailServiceApi {
      return new EmailServiceApi(this.apiConfig as Configuration);
    },
    OrderApi(): OrderServiceApi {
      return new OrderServiceApi(this.apiConfig as Configuration);
    },
    ProductApi(): ProductServiceApi {
      return new ProductServiceApi(this.apiConfig as Configuration);
    },
    ScrapBatteryApi(): ScrapBatteryPriceServiceApi {
      return new ScrapBatteryPriceServiceApi(this.apiConfig as Configuration);
    },
    RetailerUserApi(): UserServiceApi {
      return new UserServiceApi(this.apiConfig as Configuration);
    },
  },
  actions: {
    async loadCurrentUser() {
      try {
        this.currentUser = await this.AuthApi.authenticate();
      } catch (e) {
        const debugStore = useDebugStore();
        debugStore.printDebugInfo('Failed to authenticate user | ', e);
      }
    },
    setCurrentUser(user: AdminUserDto) {
      this.currentUser = {
        ...this.currentUser,
        ...user,
      };
    },
    async initConfiguration() {
      this.apiConfig = new Configuration({
        basePath: API_URL,
        fetchApi: fetch,
        accessToken: this.accessToken,
        middleware: [new ErrorHandlingMiddleware()],
      });
      await this.loadCurrentUser();
    },
    async setAccessToken(token: string) {
      this.accessToken = token;
      try {
        localStorage.setItem('accessToken', token);
      } catch (e) {
        console.error('Failed to set access token in local storage', e);
      }
      await this.initConfiguration();
    },
  },
});
