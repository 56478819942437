import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore, useApiStore } from '@/stores';
import { routes } from '@/router/routes';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0, left: 0 };
  },
});

router.beforeEach(async (to) => {
  document.title = `${to.meta.title} | Areeb Admin`;

  const apiStore = useApiStore();
  const authStore = useAuthStore();

  const publicPages = ['/login', '/register', '/verify'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !apiStore.currentUser) {
    authStore.returnUrl = to.fullPath;
    return '/login';
  }
});
