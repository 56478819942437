/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @type ProductDtoPrice
 * Product price
 * @export
 */
export type ProductDtoPrice = Array<ProductPriceDto> | number;

export function ProductDtoPriceFromJSON(json: any): ProductDtoPrice {
    return ProductDtoPriceFromJSONTyped(json, false);
}

export function ProductDtoPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDtoPrice {
    if (json == null) {
        return json;
    }
    if (instanceOfArray<ProductPriceDto>(json)) {
        return Array<ProductPriceDto>FromJSONTyped(json, true);
    }
    if (instanceOfnumber(json)) {
        return numberFromJSONTyped(json, true);
    }
}

export function ProductDtoPriceToJSON(value?: ProductDtoPrice | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfArray<ProductPriceDto>(value)) {
        return Array<ProductPriceDto>ToJSON(value as Array<ProductPriceDto>);
    }
    if (instanceOfnumber(value)) {
        return numberToJSON(value as number);
    }

    return {};
}

