import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { getCurrentInstance } from 'vue';

export interface IDebugState {
  isDebugEnabled: boolean;
}

export const useDebugStore = defineStore({
  id: 'debug',
  state: (): IDebugState => ({
    isDebugEnabled: useStorage('debug', true).value,
  }),
  getters: {
    getCallerName() {
      return {
        name: getCurrentInstance()?.type?.__name || new Error()?.stack?.split('\n')[2]?.trim()?.split(' ')[1],
        stack: new Error()?.stack,
      };
    },
  },
  actions: {
    printDebugInfo(...args: unknown[]) {
      if (this.isDebugEnabled) {
        console.debug(...args);
      }
    },
  },
});
