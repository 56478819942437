/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @type DeliveryDtoDeliveryFee
 * Delivery fee
 * @export
 */
export type DeliveryDtoDeliveryFee = Array<DeliveryFeeDto> | number;

export function DeliveryDtoDeliveryFeeFromJSON(json: any): DeliveryDtoDeliveryFee {
    return DeliveryDtoDeliveryFeeFromJSONTyped(json, false);
}

export function DeliveryDtoDeliveryFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryDtoDeliveryFee {
    if (json == null) {
        return json;
    }
    if (instanceOfArray<DeliveryFeeDto>(json)) {
        return Array<DeliveryFeeDto>FromJSONTyped(json, true);
    }
    if (instanceOfnumber(json)) {
        return numberFromJSONTyped(json, true);
    }
}

export function DeliveryDtoDeliveryFeeToJSON(value?: DeliveryDtoDeliveryFee | null): any {
    if (value == null) {
        return value;
    }

    if (instanceOfArray<DeliveryFeeDto>(value)) {
        return Array<DeliveryFeeDto>ToJSON(value as Array<DeliveryFeeDto>);
    }
    if (instanceOfnumber(value)) {
        return numberToJSON(value as number);
    }

    return {};
}

