import { defineStore } from 'pinia';
import type { CustomErrorResponseDto } from '@/services';

export interface IAlertState {
  errorResponse: CustomErrorResponseDto | null;
  successMessages: string[] | null;
  warningMessages: string[] | null;
}

export const useAlertStore = defineStore({
  id: 'alert',
  state: (): IAlertState => ({
    errorResponse: null,
    successMessages: null,
    warningMessages: null,
  }),
  actions: {
    setErrorResponse(errorResponse: CustomErrorResponseDto) {
      this.errorResponse = errorResponse;
    },
    clearErrorResponse() {
      this.errorResponse = null;
    },
    setSuccessMessages(successMessages: string[]) {
      this.successMessages = successMessages;
    },
    clearSuccessMessages() {
      this.successMessages = null;
    },
    setWarningMessages(warningMessages: string[]) {
      this.warningMessages = warningMessages;
    },
    clearWarningMessages() {
      this.warningMessages = null;
    },
    clearAll() {
      this.errorResponse = null;
      this.successMessages = null;
      this.warningMessages = null;
    },
  },
});
