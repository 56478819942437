/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResendVerificationEmailDto
 */
export interface ResendVerificationEmailDto {
    /**
     * Email
     * @type {string}
     * @memberof ResendVerificationEmailDto
     */
    email: string;
}

/**
 * Check if a given object implements the ResendVerificationEmailDto interface.
 */
export function instanceOfResendVerificationEmailDto(value: object): value is ResendVerificationEmailDto {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function ResendVerificationEmailDtoFromJSON(json: any): ResendVerificationEmailDto {
    return ResendVerificationEmailDtoFromJSONTyped(json, false);
}

export function ResendVerificationEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendVerificationEmailDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function ResendVerificationEmailDtoToJSON(value?: ResendVerificationEmailDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

