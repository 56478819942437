/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchDto } from './BranchDto';
import {
    BranchDtoFromJSON,
    BranchDtoFromJSONTyped,
    BranchDtoToJSON,
} from './BranchDto';
import type { UserDtoCart } from './UserDtoCart';
import {
    UserDtoCartFromJSON,
    UserDtoCartFromJSONTyped,
    UserDtoCartToJSON,
} from './UserDtoCart';
import type { OrganizationDto } from './OrganizationDto';
import {
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './OrganizationDto';

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * User ID equal to the Cognito user ID
     * @type {string}
     * @memberof UserDto
     */
    userId: string;
    /**
     * User firstName
     * @type {string}
     * @memberof UserDto
     */
    firstName: string;
    /**
     * User last name
     * @type {string}
     * @memberof UserDto
     */
    lastName: string;
    /**
     * User full name. Not persisted in the database
     * @type {string}
     * @memberof UserDto
     */
    combinedName: string;
    /**
     * User email
     * @type {string}
     * @memberof UserDto
     */
    email: string | null;
    /**
     * User phone number
     * @type {string}
     * @memberof UserDto
     */
    phoneNumber: string;
    /**
     * User device ID
     * @type {string}
     * @memberof UserDto
     */
    deviceId: string;
    /**
     * User locale (language)
     * @type {string}
     * @memberof UserDto
     */
    locale: string;
    /**
     * User role
     * @type {number}
     * @memberof UserDto
     */
    role: number | null;
    /**
     * Is user onboarded flag
     * @type {boolean}
     * @memberof UserDto
     */
    onboarded: boolean;
    /**
     * Organization to which the user belongs
     * @type {OrganizationDto}
     * @memberof UserDto
     */
    organization?: OrganizationDto;
    /**
     * Organization ID
     * @type {number}
     * @memberof UserDto
     */
    organizationId: number | null;
    /**
     * Active branch ID
     * @type {number}
     * @memberof UserDto
     */
    activeBranchId: number | null;
    /**
     * Active branch currency
     * @type {string}
     * @memberof UserDto
     */
    activeBranchCurrency: UserDtoActiveBranchCurrencyEnum | null;
    /**
     * Branches to which the user belongs
     * @type {Array<BranchDto>}
     * @memberof UserDto
     */
    branches?: Array<BranchDto>;
    /**
     * User organization
     * @type {Array<OrganizationDto>}
     * @memberof UserDto
     */
    orders?: Array<OrganizationDto>;
    /**
     * 
     * @type {UserDtoCart}
     * @memberof UserDto
     */
    cart: UserDtoCart | null;
    /**
     * Cart ID
     * @type {number}
     * @memberof UserDto
     */
    cartId: number | null;
    /**
     * Timestamp user was created at
     * @type {Date}
     * @memberof UserDto
     */
    createdAt: Date;
    /**
     * Timestamp user was updated at
     * @type {Date}
     * @memberof UserDto
     */
    updatedAt: Date;
    /**
     * Soft delete flag
     * @type {boolean}
     * @memberof UserDto
     */
    disabled: boolean;
}


/**
 * @export
 */
export const UserDtoActiveBranchCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type UserDtoActiveBranchCurrencyEnum = typeof UserDtoActiveBranchCurrencyEnum[keyof typeof UserDtoActiveBranchCurrencyEnum];


/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): value is UserDto {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('combinedName' in value) || value['combinedName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('deviceId' in value) || value['deviceId'] === undefined) return false;
    if (!('locale' in value) || value['locale'] === undefined) return false;
    if (!('role' in value) || value['role'] === undefined) return false;
    if (!('onboarded' in value) || value['onboarded'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('activeBranchId' in value) || value['activeBranchId'] === undefined) return false;
    if (!('activeBranchCurrency' in value) || value['activeBranchCurrency'] === undefined) return false;
    if (!('cart' in value) || value['cart'] === undefined) return false;
    if (!('cartId' in value) || value['cartId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('disabled' in value) || value['disabled'] === undefined) return false;
    return true;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'combinedName': json['combinedName'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'deviceId': json['deviceId'],
        'locale': json['locale'],
        'role': json['role'],
        'onboarded': json['onboarded'],
        'organization': json['organization'] == null ? undefined : OrganizationDtoFromJSON(json['organization']),
        'organizationId': json['organizationId'],
        'activeBranchId': json['activeBranchId'],
        'activeBranchCurrency': json['activeBranchCurrency'],
        'branches': json['branches'] == null ? undefined : ((json['branches'] as Array<any>).map(BranchDtoFromJSON)),
        'orders': json['orders'] == null ? undefined : ((json['orders'] as Array<any>).map(OrganizationDtoFromJSON)),
        'cart': UserDtoCartFromJSON(json['cart']),
        'cartId': json['cartId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'disabled': json['disabled'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'combinedName': value['combinedName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'deviceId': value['deviceId'],
        'locale': value['locale'],
        'role': value['role'],
        'onboarded': value['onboarded'],
        'organization': OrganizationDtoToJSON(value['organization']),
        'organizationId': value['organizationId'],
        'activeBranchId': value['activeBranchId'],
        'activeBranchCurrency': value['activeBranchCurrency'],
        'branches': value['branches'] == null ? undefined : ((value['branches'] as Array<any>).map(BranchDtoToJSON)),
        'orders': value['orders'] == null ? undefined : ((value['orders'] as Array<any>).map(OrganizationDtoToJSON)),
        'cart': UserDtoCartToJSON(value['cart']),
        'cartId': value['cartId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'disabled': value['disabled'],
    };
}

