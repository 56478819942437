/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccessTokenDto,
  AdminUserDto,
  LoginAdminUserDto,
  RegisterAdminUserDto,
  ResendVerificationEmailDto,
  VerificationTokenDto,
} from '../models/index';
import {
    AccessTokenDtoFromJSON,
    AccessTokenDtoToJSON,
    AdminUserDtoFromJSON,
    AdminUserDtoToJSON,
    LoginAdminUserDtoFromJSON,
    LoginAdminUserDtoToJSON,
    RegisterAdminUserDtoFromJSON,
    RegisterAdminUserDtoToJSON,
    ResendVerificationEmailDtoFromJSON,
    ResendVerificationEmailDtoToJSON,
    VerificationTokenDtoFromJSON,
    VerificationTokenDtoToJSON,
} from '../models/index';

export interface LogInRequest {
    loginAdminUserDto: LoginAdminUserDto;
}

export interface RegisterRequest {
    registerAdminUserDto: RegisterAdminUserDto;
}

export interface ResendVerificationLinkRequest {
    resendVerificationEmailDto: ResendVerificationEmailDto;
}

export interface VerifyEmailRequest {
    verificationTokenDto: VerificationTokenDto;
}

/**
 * 
 */
export class AreebAuthServiceApi extends runtime.BaseAPI {

    /**
     */
    async authenticateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/areeb-auth/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async authenticate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserDto> {
        const response = await this.authenticateRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async logInRaw(requestParameters: LogInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessTokenDto>> {
        if (requestParameters['loginAdminUserDto'] == null) {
            throw new runtime.RequiredError(
                'loginAdminUserDto',
                'Required parameter "loginAdminUserDto" was null or undefined when calling logIn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/areeb-auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginAdminUserDtoToJSON(requestParameters['loginAdminUserDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenDtoFromJSON(jsonValue));
    }

    /**
     */
    async logIn(requestParameters: LogInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessTokenDto> {
        const response = await this.logInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async registerRaw(requestParameters: RegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserDto>> {
        if (requestParameters['registerAdminUserDto'] == null) {
            throw new runtime.RequiredError(
                'registerAdminUserDto',
                'Required parameter "registerAdminUserDto" was null or undefined when calling register().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/areeb-auth/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterAdminUserDtoToJSON(requestParameters['registerAdminUserDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async register(requestParameters: RegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserDto> {
        const response = await this.registerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async resendVerificationLinkRaw(requestParameters: ResendVerificationLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['resendVerificationEmailDto'] == null) {
            throw new runtime.RequiredError(
                'resendVerificationEmailDto',
                'Required parameter "resendVerificationEmailDto" was null or undefined when calling resendVerificationLink().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/areeb-auth/resendVerification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendVerificationEmailDtoToJSON(requestParameters['resendVerificationEmailDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resendVerificationLink(requestParameters: ResendVerificationLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resendVerificationLinkRaw(requestParameters, initOverrides);
    }

    /**
     */
    async verifyEmailRaw(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['verificationTokenDto'] == null) {
            throw new runtime.RequiredError(
                'verificationTokenDto',
                'Required parameter "verificationTokenDto" was null or undefined when calling verifyEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/areeb-auth/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerificationTokenDtoToJSON(requestParameters['verificationTokenDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async verifyEmail(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyEmailRaw(requestParameters, initOverrides);
    }

}
