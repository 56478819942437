/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContactInfoDto } from './ContactInfoDto';
import {
    ContactInfoDtoFromJSON,
    ContactInfoDtoFromJSONTyped,
    ContactInfoDtoToJSON,
} from './ContactInfoDto';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';
import type { OrderDto } from './OrderDto';
import {
    OrderDtoFromJSON,
    OrderDtoFromJSONTyped,
    OrderDtoToJSON,
} from './OrderDto';

/**
 * 
 * @export
 * @interface BranchDto
 */
export interface BranchDto {
    /**
     * Branch ID
     * @type {number}
     * @memberof BranchDto
     */
    id: number;
    /**
     * Branch name
     * @type {string}
     * @memberof BranchDto
     */
    branchName: string;
    /**
     * Branch contact info including address, phone number, etc..
     * @type {ContactInfoDto}
     * @memberof BranchDto
     */
    contactInfo: ContactInfoDto;
    /**
     * Branch contact info ID
     * @type {number}
     * @memberof BranchDto
     */
    contactInfoId: number;
    /**
     * Branch currency
     * @type {string}
     * @memberof BranchDto
     */
    currency: BranchDtoCurrencyEnum;
    /**
     * Flag indicating if branch is within allowed delivery zones
     * @type {boolean}
     * @memberof BranchDto
     */
    canDeliverTo: boolean;
    /**
     * Organization ID
     * @type {number}
     * @memberof BranchDto
     */
    organizationId: number;
    /**
     * List of users belonging to the branch
     * @type {UserDto}
     * @memberof BranchDto
     */
    users?: UserDto;
    /**
     * Branch orders
     * @type {Array<OrderDto>}
     * @memberof BranchDto
     */
    orders?: Array<OrderDto>;
    /**
     * Timestamp branch was created at
     * @type {Date}
     * @memberof BranchDto
     */
    createdAt: Date;
    /**
     * Timestamp branch was updated at
     * @type {Date}
     * @memberof BranchDto
     */
    updatedAt: Date;
    /**
     * Soft delete flag
     * @type {boolean}
     * @memberof BranchDto
     */
    disabled: boolean;
}


/**
 * @export
 */
export const BranchDtoCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type BranchDtoCurrencyEnum = typeof BranchDtoCurrencyEnum[keyof typeof BranchDtoCurrencyEnum];


/**
 * Check if a given object implements the BranchDto interface.
 */
export function instanceOfBranchDto(value: object): value is BranchDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('branchName' in value) || value['branchName'] === undefined) return false;
    if (!('contactInfo' in value) || value['contactInfo'] === undefined) return false;
    if (!('contactInfoId' in value) || value['contactInfoId'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('canDeliverTo' in value) || value['canDeliverTo'] === undefined) return false;
    if (!('organizationId' in value) || value['organizationId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('disabled' in value) || value['disabled'] === undefined) return false;
    return true;
}

export function BranchDtoFromJSON(json: any): BranchDto {
    return BranchDtoFromJSONTyped(json, false);
}

export function BranchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'branchName': json['branchName'],
        'contactInfo': ContactInfoDtoFromJSON(json['contactInfo']),
        'contactInfoId': json['contactInfoId'],
        'currency': json['currency'],
        'canDeliverTo': json['canDeliverTo'],
        'organizationId': json['organizationId'],
        'users': json['users'] == null ? undefined : UserDtoFromJSON(json['users']),
        'orders': json['orders'] == null ? undefined : ((json['orders'] as Array<any>).map(OrderDtoFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'disabled': json['disabled'],
    };
}

export function BranchDtoToJSON(value?: BranchDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'branchName': value['branchName'],
        'contactInfo': ContactInfoDtoToJSON(value['contactInfo']),
        'contactInfoId': value['contactInfoId'],
        'currency': value['currency'],
        'canDeliverTo': value['canDeliverTo'],
        'organizationId': value['organizationId'],
        'users': UserDtoToJSON(value['users']),
        'orders': value['orders'] == null ? undefined : ((value['orders'] as Array<any>).map(OrderDtoToJSON)),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'disabled': value['disabled'],
    };
}

