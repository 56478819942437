/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CartItemDto
 */
export interface CartItemDto {
    /**
     * Cart item ID
     * @type {number}
     * @memberof CartItemDto
     */
    id: number;
    /**
     * Product quantity
     * @type {number}
     * @memberof CartItemDto
     */
    quantity: number;
    /**
     * Cart ID to which the cart item belongs
     * @type {number}
     * @memberof CartItemDto
     */
    cartId: number;
    /**
     * Product
     * @type {number}
     * @memberof CartItemDto
     */
    product?: number;
    /**
     * Product ID
     * @type {number}
     * @memberof CartItemDto
     */
    productId: number;
    /**
     * Product name
     * @type {string}
     * @memberof CartItemDto
     */
    productName: string;
    /**
     * Product image url
     * @type {string}
     * @memberof CartItemDto
     */
    productImageUrl: string;
    /**
     * Unit price of the product.
     * @type {number}
     * @memberof CartItemDto
     */
    unitPrice: number;
    /**
     * Total price of the product quantity. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof CartItemDto
     */
    price: number;
    /**
     * Total tax of the product quantity. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof CartItemDto
     */
    tax: number;
    /**
     * Total discount of the product quantity. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof CartItemDto
     */
    discount: number;
    /**
     * Total volume of the product quantity. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof CartItemDto
     */
    volume: number;
    /**
     * Total footprint of the product quantity. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof CartItemDto
     */
    footprint: number;
    /**
     * Total weight of the product quantity. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof CartItemDto
     */
    weight: number;
    /**
     * Timestamp cart item was created at
     * @type {Date}
     * @memberof CartItemDto
     */
    createdAt?: Date;
    /**
     * Timestamp cart item was updated at
     * @type {Date}
     * @memberof CartItemDto
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the CartItemDto interface.
 */
export function instanceOfCartItemDto(value: object): value is CartItemDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    if (!('cartId' in value) || value['cartId'] === undefined) return false;
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('productName' in value) || value['productName'] === undefined) return false;
    if (!('productImageUrl' in value) || value['productImageUrl'] === undefined) return false;
    if (!('unitPrice' in value) || value['unitPrice'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('tax' in value) || value['tax'] === undefined) return false;
    if (!('discount' in value) || value['discount'] === undefined) return false;
    if (!('volume' in value) || value['volume'] === undefined) return false;
    if (!('footprint' in value) || value['footprint'] === undefined) return false;
    if (!('weight' in value) || value['weight'] === undefined) return false;
    return true;
}

export function CartItemDtoFromJSON(json: any): CartItemDto {
    return CartItemDtoFromJSONTyped(json, false);
}

export function CartItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'quantity': json['quantity'],
        'cartId': json['cartId'],
        'product': json['product'] == null ? undefined : json['product'],
        'productId': json['productId'],
        'productName': json['productName'],
        'productImageUrl': json['productImageUrl'],
        'unitPrice': json['unitPrice'],
        'price': json['price'],
        'tax': json['tax'],
        'discount': json['discount'],
        'volume': json['volume'],
        'footprint': json['footprint'],
        'weight': json['weight'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
    };
}

export function CartItemDtoToJSON(value?: CartItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'quantity': value['quantity'],
        'cartId': value['cartId'],
        'product': value['product'],
        'productId': value['productId'],
        'productName': value['productName'],
        'productImageUrl': value['productImageUrl'],
        'unitPrice': value['unitPrice'],
        'price': value['price'],
        'tax': value['tax'],
        'discount': value['discount'],
        'volume': value['volume'],
        'footprint': value['footprint'],
        'weight': value['weight'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

