/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * User ID in AWS Cognito
     * @type {string}
     * @memberof CreateUserDto
     */
    userId: string;
    /**
     * User first name
     * @type {string}
     * @memberof CreateUserDto
     */
    firstName: string;
    /**
     * User last name
     * @type {string}
     * @memberof CreateUserDto
     */
    lastName: string;
    /**
     * User email
     * @type {string}
     * @memberof CreateUserDto
     */
    email: string;
    /**
     * User phone number
     * @type {string}
     * @memberof CreateUserDto
     */
    phoneNumber: string;
    /**
     * User device id
     * @type {string}
     * @memberof CreateUserDto
     */
    deviceId: string;
    /**
     * User locale (language)
     * @type {string}
     * @memberof CreateUserDto
     */
    locale: string;
    /**
     * User role
     * @type {number}
     * @memberof CreateUserDto
     */
    role?: number;
    /**
     * Is user onboarded flag
     * @type {boolean}
     * @memberof CreateUserDto
     */
    onboarded?: boolean;
}

/**
 * Check if a given object implements the CreateUserDto interface.
 */
export function instanceOfCreateUserDto(value: object): value is CreateUserDto {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('deviceId' in value) || value['deviceId'] === undefined) return false;
    if (!('locale' in value) || value['locale'] === undefined) return false;
    return true;
}

export function CreateUserDtoFromJSON(json: any): CreateUserDto {
    return CreateUserDtoFromJSONTyped(json, false);
}

export function CreateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'deviceId': json['deviceId'],
        'locale': json['locale'],
        'role': json['role'] == null ? undefined : json['role'],
        'onboarded': json['onboarded'] == null ? undefined : json['onboarded'],
    };
}

export function CreateUserDtoToJSON(value?: CreateUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'deviceId': value['deviceId'],
        'locale': value['locale'],
        'role': value['role'],
        'onboarded': value['onboarded'],
    };
}

