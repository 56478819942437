/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrUpdateScrapBatteryPriceDto
 */
export interface CreateOrUpdateScrapBatteryPriceDto {
    /**
     * Scrap battery price value per Kg
     * @type {number}
     * @memberof CreateOrUpdateScrapBatteryPriceDto
     */
    price: number;
    /**
     * Scrap battery price currency
     * @type {string}
     * @memberof CreateOrUpdateScrapBatteryPriceDto
     */
    currency: CreateOrUpdateScrapBatteryPriceDtoCurrencyEnum;
}


/**
 * @export
 */
export const CreateOrUpdateScrapBatteryPriceDtoCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type CreateOrUpdateScrapBatteryPriceDtoCurrencyEnum = typeof CreateOrUpdateScrapBatteryPriceDtoCurrencyEnum[keyof typeof CreateOrUpdateScrapBatteryPriceDtoCurrencyEnum];


/**
 * Check if a given object implements the CreateOrUpdateScrapBatteryPriceDto interface.
 */
export function instanceOfCreateOrUpdateScrapBatteryPriceDto(value: object): value is CreateOrUpdateScrapBatteryPriceDto {
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function CreateOrUpdateScrapBatteryPriceDtoFromJSON(json: any): CreateOrUpdateScrapBatteryPriceDto {
    return CreateOrUpdateScrapBatteryPriceDtoFromJSONTyped(json, false);
}

export function CreateOrUpdateScrapBatteryPriceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrUpdateScrapBatteryPriceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'price': json['price'],
        'currency': json['currency'],
    };
}

export function CreateOrUpdateScrapBatteryPriceDtoToJSON(value?: CreateOrUpdateScrapBatteryPriceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'price': value['price'],
        'currency': value['currency'],
    };
}

