/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrUpdateDeliveryFeeDto
 */
export interface CreateOrUpdateDeliveryFeeDto {
    /**
     * Delivery fee value
     * @type {number}
     * @memberof CreateOrUpdateDeliveryFeeDto
     */
    fee: number;
    /**
     * Delivery fee currency
     * @type {string}
     * @memberof CreateOrUpdateDeliveryFeeDto
     */
    currency: CreateOrUpdateDeliveryFeeDtoCurrencyEnum;
}


/**
 * @export
 */
export const CreateOrUpdateDeliveryFeeDtoCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type CreateOrUpdateDeliveryFeeDtoCurrencyEnum = typeof CreateOrUpdateDeliveryFeeDtoCurrencyEnum[keyof typeof CreateOrUpdateDeliveryFeeDtoCurrencyEnum];


/**
 * Check if a given object implements the CreateOrUpdateDeliveryFeeDto interface.
 */
export function instanceOfCreateOrUpdateDeliveryFeeDto(value: object): value is CreateOrUpdateDeliveryFeeDto {
    if (!('fee' in value) || value['fee'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    return true;
}

export function CreateOrUpdateDeliveryFeeDtoFromJSON(json: any): CreateOrUpdateDeliveryFeeDto {
    return CreateOrUpdateDeliveryFeeDtoFromJSONTyped(json, false);
}

export function CreateOrUpdateDeliveryFeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrUpdateDeliveryFeeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'fee': json['fee'],
        'currency': json['currency'],
    };
}

export function CreateOrUpdateDeliveryFeeDtoToJSON(value?: CreateOrUpdateDeliveryFeeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fee': value['fee'],
        'currency': value['currency'],
    };
}

