/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * User first name
     * @type {string}
     * @memberof UpdateUserDto
     */
    firstName?: string;
    /**
     * User last name
     * @type {string}
     * @memberof UpdateUserDto
     */
    lastName?: string;
    /**
     * User email
     * @type {string}
     * @memberof UpdateUserDto
     */
    email?: string;
    /**
     * User phone number
     * @type {string}
     * @memberof UpdateUserDto
     */
    phoneNumber?: string;
    /**
     * User device id
     * @type {string}
     * @memberof UpdateUserDto
     */
    deviceId?: string;
    /**
     * User locale (language)
     * @type {string}
     * @memberof UpdateUserDto
     */
    locale?: string;
    /**
     * User role
     * @type {number}
     * @memberof UpdateUserDto
     */
    role?: number;
    /**
     * Is user onboarded flag
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    onboarded?: boolean;
    /**
     * Active branch ID
     * @type {number}
     * @memberof UpdateUserDto
     */
    activeBranchId: number | null;
    /**
     * Active branch currency
     * @type {string}
     * @memberof UpdateUserDto
     */
    activeBranchCurrency: UpdateUserDtoActiveBranchCurrencyEnum | null;
    /**
     * Cart ID
     * @type {number}
     * @memberof UpdateUserDto
     */
    cartId: number | null;
}


/**
 * @export
 */
export const UpdateUserDtoActiveBranchCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type UpdateUserDtoActiveBranchCurrencyEnum = typeof UpdateUserDtoActiveBranchCurrencyEnum[keyof typeof UpdateUserDtoActiveBranchCurrencyEnum];


/**
 * Check if a given object implements the UpdateUserDto interface.
 */
export function instanceOfUpdateUserDto(value: object): value is UpdateUserDto {
    if (!('activeBranchId' in value) || value['activeBranchId'] === undefined) return false;
    if (!('activeBranchCurrency' in value) || value['activeBranchCurrency'] === undefined) return false;
    if (!('cartId' in value) || value['cartId'] === undefined) return false;
    return true;
}

export function UpdateUserDtoFromJSON(json: any): UpdateUserDto {
    return UpdateUserDtoFromJSONTyped(json, false);
}

export function UpdateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'deviceId': json['deviceId'] == null ? undefined : json['deviceId'],
        'locale': json['locale'] == null ? undefined : json['locale'],
        'role': json['role'] == null ? undefined : json['role'],
        'onboarded': json['onboarded'] == null ? undefined : json['onboarded'],
        'activeBranchId': json['activeBranchId'],
        'activeBranchCurrency': json['activeBranchCurrency'],
        'cartId': json['cartId'],
    };
}

export function UpdateUserDtoToJSON(value?: UpdateUserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'deviceId': value['deviceId'],
        'locale': value['locale'],
        'role': value['role'],
        'onboarded': value['onboarded'],
        'activeBranchId': value['activeBranchId'],
        'activeBranchCurrency': value['activeBranchCurrency'],
        'cartId': value['cartId'],
    };
}

