/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryFeeDto
 */
export interface DeliveryFeeDto {
    /**
     * Delivery fee ID
     * @type {number}
     * @memberof DeliveryFeeDto
     */
    id: number;
    /**
     * Delivery fee value
     * @type {number}
     * @memberof DeliveryFeeDto
     */
    fee: number;
    /**
     * Delivery fee currency
     * @type {string}
     * @memberof DeliveryFeeDto
     */
    currency: DeliveryFeeDtoCurrencyEnum;
    /**
     * Delivery ID
     * @type {number}
     * @memberof DeliveryFeeDto
     */
    deliveryId: number;
    /**
     * Timestamp delivery fee was created at
     * @type {Date}
     * @memberof DeliveryFeeDto
     */
    createdAt?: Date;
    /**
     * Timestamp delivery fee was updated at
     * @type {Date}
     * @memberof DeliveryFeeDto
     */
    updatedAt?: Date;
}


/**
 * @export
 */
export const DeliveryFeeDtoCurrencyEnum = {
    Egp: 'EGP',
    Sar: 'SAR',
    Mad: 'MAD'
} as const;
export type DeliveryFeeDtoCurrencyEnum = typeof DeliveryFeeDtoCurrencyEnum[keyof typeof DeliveryFeeDtoCurrencyEnum];


/**
 * Check if a given object implements the DeliveryFeeDto interface.
 */
export function instanceOfDeliveryFeeDto(value: object): value is DeliveryFeeDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('fee' in value) || value['fee'] === undefined) return false;
    if (!('currency' in value) || value['currency'] === undefined) return false;
    if (!('deliveryId' in value) || value['deliveryId'] === undefined) return false;
    return true;
}

export function DeliveryFeeDtoFromJSON(json: any): DeliveryFeeDto {
    return DeliveryFeeDtoFromJSONTyped(json, false);
}

export function DeliveryFeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryFeeDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fee': json['fee'],
        'currency': json['currency'],
        'deliveryId': json['deliveryId'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
    };
}

export function DeliveryFeeDtoToJSON(value?: DeliveryFeeDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'fee': value['fee'],
        'currency': value['currency'],
        'deliveryId': value['deliveryId'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

