/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * User information
 * @export
 * @interface OrderDetailsDtoUser
 */
export interface OrderDetailsDtoUser {
    /**
     * User firstName
     * @type {string}
     * @memberof OrderDetailsDtoUser
     */
    firstName: string;
    /**
     * User last name
     * @type {string}
     * @memberof OrderDetailsDtoUser
     */
    lastName: string;
    /**
     * User full name. Not persisted in the database
     * @type {string}
     * @memberof OrderDetailsDtoUser
     */
    combinedName: string;
    /**
     * User phone number
     * @type {string}
     * @memberof OrderDetailsDtoUser
     */
    phoneNumber: string;
    /**
     * User organization
     * @type {string}
     * @memberof OrderDetailsDtoUser
     */
    organizationName?: string;
}

/**
 * Check if a given object implements the OrderDetailsDtoUser interface.
 */
export function instanceOfOrderDetailsDtoUser(value: object): value is OrderDetailsDtoUser {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('combinedName' in value) || value['combinedName'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    return true;
}

export function OrderDetailsDtoUserFromJSON(json: any): OrderDetailsDtoUser {
    return OrderDetailsDtoUserFromJSONTyped(json, false);
}

export function OrderDetailsDtoUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDetailsDtoUser {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'combinedName': json['combinedName'],
        'phoneNumber': json['phoneNumber'],
        'organizationName': json['organizationName'] == null ? undefined : json['organizationName'],
    };
}

export function OrderDetailsDtoUserToJSON(value?: OrderDetailsDtoUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'combinedName': value['combinedName'],
        'phoneNumber': value['phoneNumber'],
        'organizationName': value['organizationName'],
    };
}

