/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateOrderDto
 */
export interface UpdateOrderDto {
    /**
     * Order status
     * @type {number}
     * @memberof UpdateOrderDto
     */
    status: number;
}

/**
 * Check if a given object implements the UpdateOrderDto interface.
 */
export function instanceOfUpdateOrderDto(value: object): value is UpdateOrderDto {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function UpdateOrderDtoFromJSON(json: any): UpdateOrderDto {
    return UpdateOrderDtoFromJSONTyped(json, false);
}

export function UpdateOrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function UpdateOrderDtoToJSON(value?: UpdateOrderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
    };
}

