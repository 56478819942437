import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export interface IDarkModeState {
  darkMode: boolean;
}

export const useDarkModeStore = defineStore({
  id: 'darkMode',
  state: (): IDarkModeState => ({
    darkMode: useStorage('darkMode', ref(false)).value,
  }),
  getters: {
    isDarkMode: (state) => state.darkMode,
  },
  actions: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      document.documentElement.classList.toggle('dark', this.darkMode);
    },
  },
  hydrate(state) {
    document.documentElement.classList.toggle('dark', state.darkMode);
  },
});
