/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOrUpdateScrapBatteryPriceDto,
  ScrapBatteryPriceDto,
} from '../models/index';
import {
    CreateOrUpdateScrapBatteryPriceDtoFromJSON,
    CreateOrUpdateScrapBatteryPriceDtoToJSON,
    ScrapBatteryPriceDtoFromJSON,
    ScrapBatteryPriceDtoToJSON,
} from '../models/index';

export interface CreateOrUpdateScrapBatteryPriceRequest {
    createOrUpdateScrapBatteryPriceDto: CreateOrUpdateScrapBatteryPriceDto;
}

export interface GetScrapBatteryPriceByCurrencyRequest {
    currency: string;
}

export interface GetScrapBatteryPriceByIdRequest {
    id: number;
}

export interface RemoveRequest {
    id: number;
}

/**
 * 
 */
export class ScrapBatteryPriceServiceApi extends runtime.BaseAPI {

    /**
     * Creates Scrap battery price in database
     */
    async createOrUpdateScrapBatteryPriceRaw(requestParameters: CreateOrUpdateScrapBatteryPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScrapBatteryPriceDto>> {
        if (requestParameters['createOrUpdateScrapBatteryPriceDto'] == null) {
            throw new runtime.RequiredError(
                'createOrUpdateScrapBatteryPriceDto',
                'Required parameter "createOrUpdateScrapBatteryPriceDto" was null or undefined when calling createOrUpdateScrapBatteryPrice().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/scrap`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrUpdateScrapBatteryPriceDtoToJSON(requestParameters['createOrUpdateScrapBatteryPriceDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScrapBatteryPriceDtoFromJSON(jsonValue));
    }

    /**
     * Creates Scrap battery price in database
     */
    async createOrUpdateScrapBatteryPrice(requestParameters: CreateOrUpdateScrapBatteryPriceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScrapBatteryPriceDto> {
        const response = await this.createOrUpdateScrapBatteryPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches all Scrap battery price records
     */
    async getAllScrapBatteryPricesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScrapBatteryPriceDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/scrap`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScrapBatteryPriceDtoFromJSON));
    }

    /**
     * Fetches all Scrap battery price records
     */
    async getAllScrapBatteryPrices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScrapBatteryPriceDto>> {
        const response = await this.getAllScrapBatteryPricesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets scrap battery price by ID
     */
    async getScrapBatteryPriceByCurrencyRaw(requestParameters: GetScrapBatteryPriceByCurrencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScrapBatteryPriceDto>> {
        if (requestParameters['currency'] == null) {
            throw new runtime.RequiredError(
                'currency',
                'Required parameter "currency" was null or undefined when calling getScrapBatteryPriceByCurrency().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/scrap/currency/{currency}`.replace(`{${"currency"}}`, encodeURIComponent(String(requestParameters['currency']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScrapBatteryPriceDtoFromJSON(jsonValue));
    }

    /**
     * Gets scrap battery price by ID
     */
    async getScrapBatteryPriceByCurrency(requestParameters: GetScrapBatteryPriceByCurrencyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScrapBatteryPriceDto> {
        const response = await this.getScrapBatteryPriceByCurrencyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets scrap battery price by ID
     */
    async getScrapBatteryPriceByIdRaw(requestParameters: GetScrapBatteryPriceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScrapBatteryPriceDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getScrapBatteryPriceById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/scrap/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScrapBatteryPriceDtoFromJSON(jsonValue));
    }

    /**
     * Gets scrap battery price by ID
     */
    async getScrapBatteryPriceById(requestParameters: GetScrapBatteryPriceByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScrapBatteryPriceDto> {
        const response = await this.getScrapBatteryPriceByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes scrap battery price by ID
     */
    async removeRaw(requestParameters: RemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScrapBatteryPriceDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling remove().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("access-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/scrap/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScrapBatteryPriceDtoFromJSON(jsonValue));
    }

    /**
     * Deletes scrap battery price by ID
     */
    async remove(requestParameters: RemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScrapBatteryPriceDto> {
        const response = await this.removeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
