import { defineStore } from 'pinia';

export interface IPageLoaderState {
  loading: boolean;
  hasError: boolean;
  counter: number;
}

export const usePageLoaderStore = defineStore({
  id: 'page-loader',
  state: (): IPageLoaderState => ({
    loading: false,
    hasError: false,
    counter: 0,
  }),
  actions: {
    setLoading(loading: boolean) {
      this.counter += loading ? 1 : -1;
      this.loading = this.counter > 0;
    },
    setError(hasError: boolean) {
      this.hasError = hasError;
    },
  },
});
