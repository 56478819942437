/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminUserDto,
  CreateAdminUserDto,
  UpdateAdminUserDto,
} from '../models/index';
import {
    AdminUserDtoFromJSON,
    AdminUserDtoToJSON,
    CreateAdminUserDtoFromJSON,
    CreateAdminUserDtoToJSON,
    UpdateAdminUserDtoFromJSON,
    UpdateAdminUserDtoToJSON,
} from '../models/index';

export interface CreateAdminUserRequest {
    createAdminUserDto: CreateAdminUserDto;
}

export interface DeleteAdminUserByIdRequest {
    id: string;
}

export interface GetAdminUserByIdRequest {
    id: string;
}

export interface UpdateAdminUserRequest {
    id: string;
    updateAdminUserDto: UpdateAdminUserDto;
}

/**
 * 
 */
export class AdminUserServiceApi extends runtime.BaseAPI {

    /**
     */
    async createAdminUserRaw(requestParameters: CreateAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserDto>> {
        if (requestParameters['createAdminUserDto'] == null) {
            throw new runtime.RequiredError(
                'createAdminUserDto',
                'Required parameter "createAdminUserDto" was null or undefined when calling createAdminUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/admin-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAdminUserDtoToJSON(requestParameters['createAdminUserDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async createAdminUser(requestParameters: CreateAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserDto> {
        const response = await this.createAdminUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAdminUserByIdRaw(requestParameters: DeleteAdminUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteAdminUserById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/admin-user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async deleteAdminUserById(requestParameters: DeleteAdminUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserDto> {
        const response = await this.deleteAdminUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAdminUserByIdRaw(requestParameters: GetAdminUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAdminUserById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/admin-user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAdminUserById(requestParameters: GetAdminUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserDto> {
        const response = await this.getAdminUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllAdminUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminUserDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/admin-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminUserDtoFromJSON));
    }

    /**
     */
    async getAllAdminUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminUserDto>> {
        const response = await this.getAllAdminUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/admin-user/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async getMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserDto> {
        const response = await this.getMeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAdminUserRaw(requestParameters: UpdateAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUserDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAdminUser().'
            );
        }

        if (requestParameters['updateAdminUserDto'] == null) {
            throw new runtime.RequiredError(
                'updateAdminUserDto',
                'Required parameter "updateAdminUserDto" was null or undefined when calling updateAdminUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/admin-user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAdminUserDtoToJSON(requestParameters['updateAdminUserDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateAdminUser(requestParameters: UpdateAdminUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUserDto> {
        const response = await this.updateAdminUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
