import type { AccessTokenDto, LoginAdminUserDto, RegisterAdminUserDto, VerificationTokenDto } from '@/services';
import { defineStore } from 'pinia';
import { useApiStore } from '@/stores/api.store';
import { router } from '@/router';
import { useDebugStore } from '@/stores/debug.store';
import { useAlertStore } from '@/stores/alert.store';

export interface IAuthState {
  returnUrl: string | null;
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): IAuthState => ({
    returnUrl: null,
  }),
  actions: {
    async login(loginAdminUserDto: LoginAdminUserDto) {
      const api = useApiStore();
      try {
        const loginResponse: AccessTokenDto = await api.AuthApi.logIn({ loginAdminUserDto });
        await api.setAccessToken(loginResponse.accessToken);

        localStorage.setItem('currentUser', JSON.stringify(api.currentUser));

        console.log('returnUrl', this.returnUrl);

        await router.push(this.returnUrl || '/');
      } catch (e) {
        const debugStore = useDebugStore();
        debugStore.printDebugInfo('Login failed | ', e);
      }
    },
    async register(registerAdminUserDto: RegisterAdminUserDto) {
      const api = useApiStore();
      try {
        await api.AuthApi.register({ registerAdminUserDto });

        await router.push('/login');
      } catch (e) {
        const debugStore = useDebugStore();
        debugStore.printDebugInfo('Registration failed | ', e);
      }
    },
    async verify(verificationTokenDto: VerificationTokenDto) {
      const api = useApiStore();
      const alertStore = useAlertStore();

      try {
        await api.AuthApi.verifyEmail({ verificationTokenDto });
        alertStore.setSuccessMessages(['Congratulations, you are verified! Please login to proceed.']);
      } catch (e) {
        const debugStore = useDebugStore();
        debugStore.printDebugInfo('Verification failed | ', e);
      } finally {
        await router.push('/login');
      }
    },
    async logout() {
      const api = useApiStore();

      api.currentUser = null;
      api.accessToken = '';

      localStorage.removeItem('currentUser');

      await router.push('/login');
    },
  },
});
