/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductDtoPrice } from './ProductDtoPrice';
import {
    ProductDtoPriceFromJSON,
    ProductDtoPriceFromJSONTyped,
    ProductDtoPriceToJSON,
} from './ProductDtoPrice';

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * Product ID
     * @type {number}
     * @memberof ProductDto
     */
    id: number;
    /**
     * Product SKU
     * @type {string}
     * @memberof ProductDto
     */
    sku: string;
    /**
     * Product name in English
     * @type {string}
     * @memberof ProductDto
     */
    nameEn: string;
    /**
     * Product name in Arabic
     * @type {string}
     * @memberof ProductDto
     */
    nameAr: string;
    /**
     * Product description in English
     * @type {string}
     * @memberof ProductDto
     */
    descriptionEn: string | null;
    /**
     * Product description in Arabic
     * @type {string}
     * @memberof ProductDto
     */
    descriptionAr: string | null;
    /**
     * Product S3 bucket image URLs
     * @type {Array<string>}
     * @memberof ProductDto
     */
    imageUrls: Array<string>;
    /**
     * Flag indicating if product is in stock
     * @type {boolean}
     * @memberof ProductDto
     */
    inStock: boolean | null;
    /**
     * Product category ID
     * @type {number}
     * @memberof ProductDto
     */
    categoryId: number;
    /**
     * Product subcategory ID
     * @type {number}
     * @memberof ProductDto
     */
    subCategoryId: number;
    /**
     * Product brand ID
     * @type {number}
     * @memberof ProductDto
     */
    brandId: number;
    /**
     * Product subbrand ID
     * @type {number}
     * @memberof ProductDto
     */
    subBrandId: number;
    /**
     * Available quantity in stock
     * @type {string}
     * @memberof ProductDto
     */
    barcode: string | null;
    /**
     * 
     * @type {ProductDtoPrice}
     * @memberof ProductDto
     */
    price: ProductDtoPrice;
    /**
     * Product tax rate
     * @type {number}
     * @memberof ProductDto
     */
    taxRate: number | null;
    /**
     * Product discount rate
     * @type {number}
     * @memberof ProductDto
     */
    discountRate: number | null;
    /**
     * Product length
     * @type {number}
     * @memberof ProductDto
     */
    length: number | null;
    /**
     * Product width
     * @type {number}
     * @memberof ProductDto
     */
    width: number | null;
    /**
     * Product height
     * @type {number}
     * @memberof ProductDto
     */
    height: number | null;
    /**
     * Product volume. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof ProductDto
     */
    volume?: number;
    /**
     * Product footprint. This property is dynamically calculated and is not persisted in the database.
     * @type {number}
     * @memberof ProductDto
     */
    footprint?: number;
    /**
     * Product weight
     * @type {number}
     * @memberof ProductDto
     */
    weight: number | null;
    /**
     * Timestamp product was created at
     * @type {Date}
     * @memberof ProductDto
     */
    createdAt: Date;
    /**
     * Timestamp product was updated at
     * @type {Date}
     * @memberof ProductDto
     */
    updatedAt: Date;
    /**
     * Soft delete flag
     * @type {boolean}
     * @memberof ProductDto
     */
    disabled: boolean;
}

/**
 * Check if a given object implements the ProductDto interface.
 */
export function instanceOfProductDto(value: object): value is ProductDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('sku' in value) || value['sku'] === undefined) return false;
    if (!('nameEn' in value) || value['nameEn'] === undefined) return false;
    if (!('nameAr' in value) || value['nameAr'] === undefined) return false;
    if (!('descriptionEn' in value) || value['descriptionEn'] === undefined) return false;
    if (!('descriptionAr' in value) || value['descriptionAr'] === undefined) return false;
    if (!('imageUrls' in value) || value['imageUrls'] === undefined) return false;
    if (!('inStock' in value) || value['inStock'] === undefined) return false;
    if (!('categoryId' in value) || value['categoryId'] === undefined) return false;
    if (!('subCategoryId' in value) || value['subCategoryId'] === undefined) return false;
    if (!('brandId' in value) || value['brandId'] === undefined) return false;
    if (!('subBrandId' in value) || value['subBrandId'] === undefined) return false;
    if (!('barcode' in value) || value['barcode'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('taxRate' in value) || value['taxRate'] === undefined) return false;
    if (!('discountRate' in value) || value['discountRate'] === undefined) return false;
    if (!('length' in value) || value['length'] === undefined) return false;
    if (!('width' in value) || value['width'] === undefined) return false;
    if (!('height' in value) || value['height'] === undefined) return false;
    if (!('weight' in value) || value['weight'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('disabled' in value) || value['disabled'] === undefined) return false;
    return true;
}

export function ProductDtoFromJSON(json: any): ProductDto {
    return ProductDtoFromJSONTyped(json, false);
}

export function ProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sku': json['sku'],
        'nameEn': json['nameEn'],
        'nameAr': json['nameAr'],
        'descriptionEn': json['descriptionEn'],
        'descriptionAr': json['descriptionAr'],
        'imageUrls': json['imageUrls'],
        'inStock': json['inStock'],
        'categoryId': json['categoryId'],
        'subCategoryId': json['subCategoryId'],
        'brandId': json['brandId'],
        'subBrandId': json['subBrandId'],
        'barcode': json['barcode'],
        'price': ProductDtoPriceFromJSON(json['price']),
        'taxRate': json['taxRate'],
        'discountRate': json['discountRate'],
        'length': json['length'],
        'width': json['width'],
        'height': json['height'],
        'volume': json['volume'] == null ? undefined : json['volume'],
        'footprint': json['footprint'] == null ? undefined : json['footprint'],
        'weight': json['weight'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'disabled': json['disabled'],
    };
}

export function ProductDtoToJSON(value?: ProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'sku': value['sku'],
        'nameEn': value['nameEn'],
        'nameAr': value['nameAr'],
        'descriptionEn': value['descriptionEn'],
        'descriptionAr': value['descriptionAr'],
        'imageUrls': value['imageUrls'],
        'inStock': value['inStock'],
        'categoryId': value['categoryId'],
        'subCategoryId': value['subCategoryId'],
        'brandId': value['brandId'],
        'subBrandId': value['subBrandId'],
        'barcode': value['barcode'],
        'price': ProductDtoPriceToJSON(value['price']),
        'taxRate': value['taxRate'],
        'discountRate': value['discountRate'],
        'length': value['length'],
        'width': value['width'],
        'height': value['height'],
        'volume': value['volume'],
        'footprint': value['footprint'],
        'weight': value['weight'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'disabled': value['disabled'],
    };
}

